import httpClient from '@/libs/http-client'

export function getBettingListV2 (params) {
  const url = '/api/v2/retail/report/betStatistics'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getBettingDetail (data) {
  const url = '/api/admin/bet/betDetail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getBettingDetailV2 (params) {
  const url = '/api/bet/betDetail'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getBettingDetailV3 (params) {
  const url = '/api/bet/betDetailTest'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
