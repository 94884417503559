import httpClient from '@/libs/http-client'

export function getMainStatistics (params) {
  const url = '/api/v2/main/statistics'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMainCashReport (params) {
  const url = '/api/v2/main/cashReport'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMainMyCashReport (params) {
  const url = '/api/v2/main/myCashReport'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMainNotice (params) {
  const url = '/api/v2/main/notiList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMainNewPay (params) {
  const url = '/api/v2/main/newPayLog'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
