import httpClient from '@/libs/http-client'

export function getGameStaticsDailyBettingV2 (params) {
  const url = '/api/v2/retail/daily/betting'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGameStaticsDailyUsersV2 (params) {
  const url = '/api/v2/retail/daily/users'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGameStaticsDailyPartnerV2 (params) {
  const url = '/api/v2/retail/daily/partners'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
