<template>
  <div class="modal-wrapper">
    <div class="modal-pay">
      <div class="modal-title">
        <h2>요율</h2>
        <!-- <h4></h4> -->
        <a class="close-btn" @click="$emit('close')"><img src="@/assets/img/icon_cancel.svg" /></a>
      </div>
      <div class="modal-content">
        <table>
          <colgroup>
            <col width="44%" />
            <col width="23%" />
            <col width="23%" />
          </colgroup>
          <template v-for="item in rateInfoList" :key="item.gameCategoryName">
            <tr>
              <th>{{item.gameCategoryName}}</th>
              <td>롤링:<span>{{item.pointRate}}%</span></td>
              <td>루징:<span>{{item.loseRate}}%</span></td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import { getRateInfoList } from '@/api/retail'

export default {
  name: 'RateModal',
  props: [
    'selectMem',
    'selectIdx'
  ],
  data () {
    return {
      rateInfoList: []
    }
  },
  created () {
    if (this.selectMem) {
      this.load()
    }
  },
  methods: {
    async load () {
      this.modalActive = !this.modalActive
      const req = {
        memId: this.selectMem.memId
      }
      getRateInfoList(req).then(res => {
        console.log(res)
        if (res.resultCode === '0') {
          this.rateInfoList = res.data.rateInfoList
        }
      })
    }
  }
}
</script>

<style scoped>
  .modal-wrapper {
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, .4196078431372549);
  }
  .modal-pay {
    width: 385px;
    min-width: 385px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #c1c1c1;
    box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, .16);
    padding: 15px 25px;
    background: #fafafa;
    box-sizing: border-box;
  }
  .modal-title {
    color: #646464;
    font-size: 13px;
    padding-bottom: 11px;
    border-bottom: 2px solid #959595;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-title h2 {
    letter-spacing: -.39px;
    font-weight: 800;
  }
  .modal-title .close-btn {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    color: #646464;
    cursor: pointer;
  }
  .modal-title .close-btn img {
    width: 100%;
  }
  .modal-content {
    padding-top: 10px;
    color: #343434;
    font-size: 12px;
  }
  table {table-layout: fixed; width: 100%; text-align: center;}
  table th, table td {
    height: 30px;
    background: #f5f5f5;
    line-height: 30px;
    border-bottom: 1px solid #cacaca;
  }
  table th {
    background: #525976;
    color: #fff;
    border: 1px solid #c8d4e4;
  }
  table td {
    box-sizing: border-box;
  }
  table tr:nth-child(odd) td {
    background: #fff;
  }
</style>
