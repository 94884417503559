<template>
  <div class="tab-content">
      <div class="pagenamPT">
        <h3 class="pagename2">전체 파트너 목록</h3>
      </div>
      <div class="partnerTop">
        <div></div>
        <date-filter-Pt :startDate="infoDate.startDate"
                        :endDate="infoDate.endDate"
                        @update="updateInfoDate"
                        @search="getMyPartnerList" />
      </div>
      <div class="strTableWrap">
        <PartnerHierarchy @onClickMemId="getMyPartnerList"></PartnerHierarchy>
        <div class="w100">
          <div class="strTablescr">
            <div class="partnerInfo">
              <h4 class="title">※ 파트너 정보 조회</h4>
              <div class="infoView" v-if="partnerInfo">
                <ul>
                  <li>기본정보</li>
                  <li>
                    <em>구분</em>
                    <span>{{partnerInfo.partnerLevelName}}</span>
                  </li>
                  <li>
                    <em>접속 ID / 닉네임</em>
                    <span>{{partnerInfo.memId}} / {{partnerInfo.memNick}}</span>
                  </li>
                  <li>
                    <em>보유머니 / 보유롤링금</em>
                    <span>{{thousand(partnerInfo.cashAmt || 0)}} / {{thousand(partnerInfo.pointAmt || 0)}}</span>
                  </li>
                  <li>
                    <em>전화번호</em>
                    <span class="number">
                      <input type="text" v-model="partnerInfo.memPhone"/>
                      <a class="btnp" @click="onChangePhone(partnerInfo.memId, partnerInfo.memPhone)">변경</a>
                    </span>
                  </li>
                </ul>
                <ul>
                  <li>롤링 & 루징</li>
                  <li>
                    <em>롤링 % (카지노)</em>
                    <span>{{partnerInfo.casinoRate}}</span>
                  </li>
                  <li>
                    <em>롤링 % (슬롯)</em>
                    <span>{{partnerInfo.slotRate}}</span>
                  </li>
                  <li>
                    <em>루징 % (카지노)</em>
                    <span>{{partnerInfo.casinoLosing}}</span>
                  </li>
                  <li>
                    <em>루징 % (슬롯)</em>
                    <span>{{partnerInfo.slotLosing}}</span>
                  </li>
                </ul>
                <ul>
                  <li>베팅 및 롤링금</li>
                  <li>
                    <em>베팅 / 당첨금 (카지노)</em>
                    <span>{{thousand(partnerInfo.casinoBetAmt || 0)}} / {{thousand(partnerInfo.casinoWinAmt || 0)}}</span>
                  </li>
                  <li>
                    <em>베팅 / 당첨금 (슬롯)</em>
                    <span>{{thousand(partnerInfo.slotBetAmt || 0)}} / {{thousand(partnerInfo.slotWinAmt || 0)}}</span>
                  </li>
                  <li>
                    <em>롤링금 (카지노)</em>
                    <span>{{thousand(partnerInfo.casinoRolling || 0)}}</span>
                  </li>
                  <li>
                    <em>롤링금 (슬롯)</em>
                    <span>{{thousand(partnerInfo.slotRolling || 0)}}</span>
                  </li>
                </ul>
                <ul>
                  <li>자금이동</li>
                  <li>
                    <em>충전금 합계</em>
                    <span>{{thousand(partnerInfo.cashIn || 0)}}</span>
                  </li>
                  <li>
                    <em>환전금 합계</em>
                    <span>{{thousand(partnerInfo.cashOut || 0)}}</span>
                  </li>
                  <li>
                    <em>보유머니지급금 합계</em>
                    <span>{{thousand(partnerInfo.payment || 0)}}</span>
                  </li>
                  <li>
                    <em>보유머니회수금 합계</em>
                    <span>{{thousand(partnerInfo.payback || 0)}}</span>
                  </li>
                </ul>
              </div>
              <a class="btnAdd" @click="onChangePartnerAddPopupSub(partnerInfo)">하부생성</a>
            </div>
          </div>
          <div class="partnerTop">
            <h4 class="title">※ 하부 파트너 목록</h4>
            <div class="select">
              <a @click="partnerListOrder = 'JOIN_DESC'">생성순</a>
              <a @click="partnerListOrder = 'ID_DESC'">아이디 순</a>
              <a @click="partnerListOrder = 'CASH_DESC'">보유머니 순</a>
              <a @click="partnerListOrder = 'POINT_DESC'">보유롤링금 순</a>
            </div>
          </div>
          <div class="strTablescr">
            <div class="strTablePC">
              <table class="strTablePT">
                <colgroup>
                  <col style="width:9%;">
                  <col style="width:6%;">
                  <col style="width:10%;">
                  <col style="width:9%;">
                  <col style="width:6%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                  <col style="width:6%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                </colgroup>
                <thead>
                  <tr>
                    <th>아이디<br />닉네임</th>
                    <th>파트너등급</th>
                    <th>소속</th>
                    <th>보유머니<br />보유포인트</th>
                    <th>입출금</th>
                    <th>롤링 % (카)<br />롤링 % (슬)</th>
                    <th>루징 % (카)<br />루징 % (슬)</th>
                    <th>총보유머니<br />총보유롤링금</th>
                    <th>하부 수</th>
                    <th>회원 수</th>
                    <th>등록일시</th>
                    <th>상세정보</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="partnerSubList && partnerSubList.length > 0">
                    <tr v-for="(partner, index) in partnerSubList" :key="partner.memId">
                      <td>
                        {{partner.memId}}<br />
                        <span class="nick">{{partner.memNick}}</span>
                      </td>
                      <td>
                        <span class="box" :class="partner.partnerLevel">{{partner.partnerLevelName}}</span>
                      </td>
                      <td>
                        <div class="topwrap" v-if="partner.topUserList && partner.topUserList.length > 0 && partner.topUserList !== '0'">
                          <select class="h20 upperUser">
                            <option v-for="pt in partner.topUserList" :key="pt.recommenderId">
                                <span>[{{pt.partnerLevelName}}] </span>
                                <span>{{pt.recommenderId}}</span>
                            </option>
                          </select>
                          <i class="icon">+</i>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap">
                          <span>{{thousand(partner.cashAmt || 0)}}</span>
                          <span>{{thousand(partner.pointAmt || 0)}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <a class="inout-btn btn-green" @click="onOpenPay(partner, index)">지급회수</a>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <span class="rate-font roboto">{{partner.casinoRate}}%/{{partner.slotRate}}%</span>
                          <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <span class="rate-font roboto">{{partner.casinoLosing}}%/{{partner.slotLosing}}%</span>
                          <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap">
                          <span>{{thousand(partner.totalBalance.totalCashAmt || 0)}}</span>
                          <span class="plusAmt minusAmt">{{thousand(partner.totalBalance.totalPointAmt || 0)}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap f-center">
                          <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                            <template v-if="pt.partnerLevel !== 'NORMAL'">
                              <span>{{pt.partnerLevelName}} : {{thousand(pt.cnt || 0)}}</span>
                            </template>
                          </template>
                        </div>
                      </td>
                      <td>
                        <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                          <template v-if="pt.partnerLevel == 'NORMAL'">
                            <span>{{thousand(pt.cnt || 0)}}</span>
                          </template>
                        </template>
                      </td>
                      <td>{{partner.joinDate.replace('T', ' ')}}</td>
                      <td>
                        <div class="btn-input-wrap">
                          <a class="btnp" @click="goToDetail(partner.memId)">파트너정보 / 수정</a>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="12">하부 파트너가 없습니다.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="partnerSubList && partnerSubList.length > 0">
                  <ul v-for="(partner, index) in partnerSubList" :key="partner.memId">
                    <li>
                      <em>아이디<br />닉네임</em>
                      <div>
                        {{partner.memId}}<br />
                        <span class="nick">{{partner.memNick}}</span>
                      </div>
                    </li>
                    <li>
                      <em>파트너등급</em>
                      <div>
                        <span class="box" :class="partner.partnerLevel">{{partner.partnerLevelName}}</span>
                      </div>
                    </li>
                    <li>
                      <em>소속</em>
                      <div class="topwrap" v-if="partner.topUserList && partner.topUserList.length > 0 && partner.topUserList !== '0'">
                        <select class="h20 upperUser mw-auto">
                          <option v-for="pt in partner.topUserList" :key="pt.recommenderId">
                            <span>[{{pt.partnerLevelName}}] </span>
                            <span>{{pt.recommenderId}}</span>
                          </option>
                        </select>
                        <i class="icon">+</i>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </li>
                    <li>
                      <em>보유머니<br />보유포인트</em>
                      <div>
                        <span>{{thousand(partner.cashAmt || 0)}}</span><br />
                        <span>{{thousand(partner.pointAmt || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <em>입출금</em>
                      <div>
                        <a class="inout-btn" @click="onOpenPay(partner, index)">지급회수</a>
                      </div>
                    </li>
                    <li>
                      <em>롤링 % (카)<br />롤링 % (슬)</em>
                      <div class="m-table-r">
                        <span class="rate-font">{{partner.casinoRate}}%/{{partner.slotRate}}%</span>
                        <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                      </div>
                    </li>
                    <li>
                      <em>루징 % (카)<br />루징 % (슬)</em>
                      <div class="m-table-r">
                        <span class="rate-font">{{partner.casinoLosing}}%/{{partner.slotLosing}}%</span>
                        <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                      </div>
                    </li>
                    <li>
                      <em>총보유머니<br />총보유롤링금</em>
                      <div>
                        <span>{{thousand(partner.totalBalance.totalCashAmt || 0)}}</span><br />
                        <span class="plusAmt minusAmt">{{thousand(partner.totalBalance.totalPointAmt || 0)}}</span>
                      </div>
                    </li>
                    <li>
<!--                      <em>지사 수<br />총판 수<br />매장 수</em>-->
<!--                      <div>-->
<!--                        <span>10,000,000</span>-->
<!--                        <span>10,000,000</span>-->
<!--                        <span>10,000,000</span>-->
<!--                      </div>-->
                      <em>하부수</em>
                      <div>
                        <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                          <template v-if="pt.partnerLevel !== 'NORMAL'">
                            <span>{{pt.partnerLevelName}} : {{thousand(pt.cnt || 0)}}</span>
                          </template>
                        </template>
                      </div>
                    </li>
                    <li>
                      <em>회원 수</em>
                      <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                        <template v-if="pt.partnerLevel == 'NORMAL'">
                          <div>{{thousand(pt.cnt || 0)}}</div>
                        </template>
                      </template>
                    </li>
                    <li>
                      <em>등록일시</em>
                      <div>{{partner.joinDate.replace('T', ' ')}}</div>
                    </li>
                    <li>
                      <em>상세정보</em>
                      <div><a class="btnp" @click="goToDetail(partner.memId)">파트너정보 / 수정</a></div>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="makesub" class="moveWrap2">
        <div class="tabmenu_make moveWraphead">
          <ul>
            <li @click="currentTab = 'makeTab1'" :class="{'current': currentTab == 'makeTab1'}">파트너</li>
            <li @click="currentTab = 'makeTab2'" :class="{'current': currentTab == 'makeTab2'}">회원</li>
          </ul>
          <a class="close" @click="makesub = false"><img src="@/assets/img/icon_cancel.png" /></a>
        </div>
        <div class="tabcont_make moveWrapbody" v-if="currentTab == 'makeTab1' && newPartner.partnerLevel !== 'PTN_5'" >
          <h4>파트너 생성</h4>
          <table class="part">
            <colgroup>
              <col width="20%"/>
              <col width="30%"/>
              <col width="20%"/>
              <col width="30%"/>
            </colgroup>
            <tr>
              <th>아이디</th>
              <td><input class="in" name="newMemId" type="text" v-model="newPartner.memId" autocomplete="off" role="presentation"/></td>
              <th>비밀번호</th>
              <td><input class="in" name="newMemPass" type="password" v-model="newPartner.memPass" autocomplete="off"/></td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td><input class="in" type="text" v-model="newPartner.memNick" /></td>
              <th>출금 비밀번호</th>
              <td><input class="in password" pattern="[0-9]*" type="text" v-model="newPartner.cashOutPass" /></td>
            </tr>
            <tr>
              <th>추천인 아이디</th>
              <td>{{newPartner.recommenderId}}({{newPartner.partnerLevelName}})</td>
              <th>계좌정보</th>
              <td class="accountInfo">
                <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
                <input class="in w45w" type="text" v-model="newPartner.bankAcc"/>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td><input class="in" type="text" v-model="newPartner.memName" /></td>
              <th>전화번호</th>
              <td><input class="in" type="text" v-model="newPartner.memPhone" /></td>
            </tr>
          </table>
          <h4>게임별 요율설정</h4>
          <!--table v-if="isWideScreen">
            <tr>
              <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.roll')}}(%)</th>
              <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.lose')}}(%)</th>
            </tr>
            <tr>
              <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                {{$t(`front.gnb.${key}`)}}
              </th>
              <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                {{$t(`front.gnb.${key}`)}}
              </th>
            </tr>
            <tr>
              <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                <a class="rbnt">최대값 : {{item.maxPointRate}}</a><br>
                <a class="rbnt">최소값 : {{item.minPointRate}}</a>
              </td>
              <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                <a class="rbnt">최대값 : {{item.maxLoseRate}}</a><br>
                <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
              </td>
            </tr>
            <tr>
              <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                <input type="text" v-model="item[`${key}PR`]">
              </td>
              <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                <input type="text" v-model="item[`${key}LR`]">
              </td>
            </tr>
          </table>
          <ul class="rateset-m" v-if="!isWideScreen">
            <li class="table-name">롤링(%)</li>
            <li>
              <table>
                <tr v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                  <td>{{$t(`front.gnb.${key}`)}}</td>
                  <td>
                    <a class="rbnt">최대값 : {{item.maxPointRate}}</a><br>
                    <a class="rbnt">최소값 : {{item.minPointRate}}</a>
                  </td>
                  <td>
                    <input type="text" v-model="item[`${key}PR`]">
                  </td>
                </tr>
              </table>
            </li>
            <li class="table-name">루징(%)</li>
            <li>
              <table>
                <tr v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                  <td>{{$t(`front.gnb.${key}`)}}</td>
                  <td>
                    <a class="rbnt">최대값 : {{item.maxLoseRate}}</a><br>
                    <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
                  </td>
                  <td>
                    <input type="text" v-model="item[`${key}LR`]">
                  </td>
                </tr>
              </table>
            </li>
          </ul-->
          <a class="btn" @click="onAddPartnerSubmit">등록</a>
      </div>

        <div class="tabcont_make moveWrapbody" v-if="currentTab == 'makeTab2'">
          <h4>회원 생성</h4>
          <table class="part">
            <colgroup>
              <col width="20%"/>
              <col width="30%"/>
              <col width="20%"/>
              <col width="30%"/>
            </colgroup>
            <tr>
              <th>아이디</th>
              <td><input class="in" type="text" autocomplete="off" v-model="newMember.memId"/></td>
              <th>출금 비밀번호</th>
              <td><input class="in password" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" maxlength="4" pattern="[0-9]*" v-model="newMember.cashOutPass" inputmode="numeric"  /></td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td><input class="in" type="password" autocomplete="off" v-model="newMember.memPass"/></td>
              <th>전화번호</th>
              <td><input class="in" type="text" v-model="newMember.memPhone"/></td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td><input class="in" type="text" v-model="newMember.memNick"/></td>
              <th>추천인 아이디</th>
              <td>{{newPartner.recommenderId}}({{newPartner.partnerLevelName}})
                <input class="in" type="hidden" v-model="newMember.recommenderId"/>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td><input class="in" type="text" v-model="newMember.memName"/></td>
              <th>계좌정보</th>
              <td class="accountInfo">
                <bank-list :className="'in'" @onChange="onChangeBankMember"></bank-list>
                <input class="in w45w" type="text" v-model="newMember.bankacc" oninput="this.value = this.value.replace(/[^0-9.]/g, '')" />
              </td>
            </tr>
          </table>
          <a class="btn" @click="newMemberSignup()">등록</a>
        </div>
      </div>
      <pagination />
      <transition name="fade">
        <RateTable v-if="RateOpen" :selectMem="selectMem" :selectIdx="selectIdx"  @close="onCloseRate"/>
      </transition>
      <transition name="fade">
        <Paymodal v-if="PayMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePay"/>
      </transition>
  </div>
</template>

<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import RateTable from '@/components/common/RateTable.vue'
import Paymodal from '@/components/common/Pay.vue'
import Pagination from '@/components/ui/Pagination.vue'
import BankList from '@/components/ui/BankList.vue'
import { mapState } from 'vuex'
import { partnerRates, retailMyPartnerDirectList } from '@/api/retail'
import {
  getPartnerInfoV2,
  getPartnerListV2,
  partnerJoinV2,
  updatePartnerInfoPhoneV2
} from '@/api/v2/partner'
import {
  getDateStr,
  isValidOnlyNumber, isValidOnlyPhoneNumber,
  isValidPassword,
  isValidPasswordPartner,
  isValidUserId,
  thousand
} from '@/libs/utils'
import { addDays } from 'date-fns'
import { signUp } from '@/api/member'
import PartnerHierarchy from '@/components/common/mobile/PartnerHierarchy.vue'
import { PARTNER_LEVEL_ORDER } from '@/libs/constants'
export default {
  name: 'PartnerCash',
  components: { PartnerHierarchy, DateFilterPt, Pagination, RateTable, Paymodal, BankList },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    PARTNER_LEVEL_ORDER () {
      return PARTNER_LEVEL_ORDER
    }
  },
  data () {
    return {
      PayMopen: false,
      RateOpen: false,
      makesub: false,
      currentTab: 'makeTab1',
      partnerList: null,
      partnerSubList: null,
      partnerListOrder: 'JOIN_DESC',
      infoDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      partnerInfo: null,
      selectMem: null,
      selectIdx: null,
      selectedLi: null,
      newPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      newMember: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0
      },
      defaultMember: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0
      },
      rateMaxMinList: [],
      newPartnerLevel: {}
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    // await this.getHierarchyList('', 'main', null)
    await this.loadInfo(this.userData.memId)
    await this.loadSubList(this.userData.memId, 1)
    this.selectedLi = this.userData.memId
    this.emitter.emit('Loading', false)
  },
  watch: {
    partnerListOrder () {
      this.loadSubList(this.selectedLi, 1, 'sub')
    }
  },
  methods: {
    thousand,
    updateInfoDate (value) {
      this.infoDate = { ...value }
    },
    isMemberSignupValidate () {
      const data = this.newMember
      for (const key of Object.keys(data)) {
        const value = data[key]
        if (key === 'memId') {
          if (value === '') {
            this.onAlert('warningart', 'front.member.emptyMemId')
            return false
          }

          if (!isValidUserId(value)) {
            this.onAlert('warningart', 'api.U101')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (key === 'memNick') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemName')
            return false
          }

          if (value.length > 20 || value.length < 3) {
            this.onAlert('warningart', 'api.U105')
            return false
          }
        }

        if (key === 'memPhone') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemTel')
            return false
          }

          if (!isValidOnlyPhoneNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidMemTel')
            return false
          }
        }

        if (key === 'recommenderId') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyRecommenderId')
            return false
          }
        }

        if (key === 'bank') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankName')
            return false
          }
        }

        if (key === 'memName') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankAccountName')
            return false
          }
        }

        if (key === 'bankacc') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankNumber')
            return false
          }

          if (!isValidOnlyNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidBankNumber')
            return false
          }
        }
      }

      return true
    },
    newMemberSignup () {
      this.newMember.recommenderId = this.newPartner.recommenderId
      if (this.isMemberSignupValidate()) {
        this.newMember.memPhone = this.newMember.memPhone.toString()
        signUp(this.newMember).then(async response => {
          const result = response.data
          if (result.resultCode === '0') {
            await this.onCheck('front.member.completeSignup')
            this.makesub = false
            this.newMember = {
              ...this.defaultMember
            }
          } else {
            this.onAlert('warningart', 'api.' + result.resultCode)
          }
        }).catch(err => {
          console.error('singup err : ', err)
        })
      }
    },
    onAddPartnerSubmit () {
      this.emitter.emit('Loading', true)
      const reqData = { ...this.newPartner, rateGroup: {} }

      console.log(reqData)

      for (const groupCode in this.newPartner.rateGroup) {
        const item = this.newPartner.rateGroup[groupCode]
        const pr = item[`${groupCode}PR`]
        if (pr) {
          if (Number(item.maxPointRate) < Number(pr) || Number(item.minPointRate) > Number(pr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
            this.emitter.emit('Loading', false)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
          this.emitter.emit('Loading', false)
          return false
        }
        const lr = item[`${groupCode}LR`]
        if (lr) {
          if (Number(item.maxLoseRate) < Number(lr) || Number(item.minLoseRate) > Number(lr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
            this.emitter.emit('Loading', false)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
          this.emitter.emit('Loading', false)
          return false
        }

        reqData.rateGroup[`${groupCode}PR`] = pr
        reqData.rateGroup[`${groupCode}LR`] = lr
      }

      if (!reqData.memId) {
        this.onAlert('warningart', 'front.member.emptyMemId')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!isValidUserId(reqData.memId)) {
        this.onAlert('warningart', 'api.U101')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!reqData.memPass) {
        this.onAlert('warningart', 'front.member.emptyMemPass')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!isValidPasswordPartner(reqData.memPass) || reqData.memPass.length > 20) {
        this.onAlert('warningart', 'api.U102')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!reqData.memNick) {
        this.onAlert('warningart', 'front.member.emptyMemNick')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.cashOutPass) {
        this.onAlert('warningart', 'front.member.emptyCashOutPass')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.bank) {
        this.onAlert('warningart', 'front.member.emptyBankName')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.bankAcc) {
        this.onAlert('warningart', 'front.member.emptyBankNumber')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.memName) {
        this.onAlert('warningart', 'front.member.emptyMemName')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.memPhone) {
        this.onAlert('warningart', 'front.member.emptyMemTel')
        this.emitter.emit('Loading', false)
        return false
      }

      return partnerJoinV2(reqData).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('하위파트너 생성신청이 완료되었습니다. 관리자에서 승인되면 가입처리가 완료됩니다.')
          this.onChangePartnerAddPopupSub(this.partnerInfo)
          this.newPartner = { ...this.defaultNewPartner }
          this.emitter.emit('Loading', false)
        } else {
          console.log(res)
          this.emitter.emit('Loading', false)
          this.onAlert('warningart', result.resultMessage)
        }
      })
    },
    onChangeBank (value) {
      this.newPartner.bank = value
    },
    onChangeBankMember (value) {
      this.newMember.bank = value
    },
    async onChangePartnerAddPopupSub (currentPartner) {
      console.log(currentPartner)
      if (!this.isMemberMake(currentPartner.partnerLevel)) {
        this.currentTab = 'makeTab1'
      }

      this.makesub = !this.makesub
      if (this.makesub) {
        console.log(this.rateMaxMinList)

        const list = await this.getPartnerRateForNewPartner(currentPartner.memId)
        console.log(list)

        this.newPartner.recommenderId = currentPartner.memId
        this.newPartner.partnerLevel = currentPartner.partnerLevel
        this.newPartner.partnerLevelName = currentPartner.partnerLevelName

        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const vendorCode = item.vendorCode
          let groupCode = this.commonCodeByCode[vendorCode].groupCode
          const groupCodeName = this.commonCodeByCode[vendorCode].groupCodeName
          if (groupCode === 'minigame') {
            groupCode = 'mini'
          }
          if (groupCode === 'casino' && groupCodeName.indexOf('HC-') > -1) {
            groupCode = 'hcasino'
          }
          if (groupCode) {
            this.newPartner.rateGroup[groupCode] = {
              ...item
            }
            this.newPartner.rateGroup[groupCode][`${groupCode}PR`] = '0.0'
            this.newPartner.rateGroup[groupCode][`${groupCode}LR`] = '0.0'
          }
          console.log(groupCode)
        }
      }
    },
    getPartnerRateForNewPartner (memId) {
      const params = {}
      if (memId) {
        params.memId = memId
      } else {
        params.memId = this.userData.memId
      }
      console.log('[REQ]/api/retail/partnerRates : ', params)
      return partnerRates(params).then(res => {
        console.log('[RES]/api/retail/partnerRates : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.rateMaxMinList
          // this.rateMaxMinList = list
          return list
        }
      })
    },
    onChangePhone (memId, newPhone) {
      const params = {
        memId: memId,
        memPhone: newPhone
      }

      console.log(params)

      if (confirm('전화번호를 변경하시겠습니까?')) {
        updatePartnerInfoPhoneV2(params).then(res => {
          const result = res.data
          if (result.resultCode === '0') {
            alert('정상적으로 변경되었습니다.')
          } else {
            alert('다시 시도해주세요 :', result.resultMessage)
          }
        })
      }
    },
    goToDetail (memId) {
      this.detailOpen('partner', memId)
    },
    onCloseRate () {
      this.RateOpen = false
      this.selectMem = null
      this.selectIdx = null
    },
    onClosePay (newCash, idx) {
      if (newCash !== null && newCash !== undefined) {
        this.partnerSubList[idx].cashAmt = newCash
      }
      this.PayMopen = false
      this.selectMem = null
      this.selectIdx = null
    },
    onOpenPay (selectMem, selectIdx) {
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      this.selectIdx = selectIdx
      this.PayMopen = true
    },
    onOpenRate (selectMem, selectIdx) {
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      this.selectIdx = selectIdx
      this.RateOpen = true
    },
    async loadInfo (memId) {
      const param = {
        memId: memId,
        ...this.infoDate
      }

      getPartnerInfoV2(param).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log('loadInfo : ', result.data)
          this.partnerInfo = result.data.partnerInfo
        }
      })
    },
    async loadSubList (memId, page, type) {
      if (!page) {
        page = this.pageInfo.page
      } else {
        this.pageInfo.page = page
      }
      this.emitter.emit('Loading', true)

      const params = {
        memId: memId,
        targetLevel: '',
        searchType: 'id',
        searchValue: '',
        orderStr: this.partnerListOrder,
        page: page,
        count_per_list: 40
      }

      await getPartnerListV2(params, type).then(res => {
        this.emitter.emit('Loading', false)
        console.log('getPartnerListV2 : ', params, res)
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerSubList = result.data.list

          this.partnerSubList.forEach(item => {
            const topUserList = []
            if (item.topUserList) {
              item.topUserList.forEach(pt => {
                if (this.userData.partnerLevelOrder <= PARTNER_LEVEL_ORDER[pt.partnerLevel]) {
                  topUserList.push(pt)
                }
              })
            }
            item.topUserList = topUserList
          })
        }

        if (result.data.pageInfo) {
          this.pageInfo = result.data.pageInfo
        } else {
          this.pageInfo.page = 1
          this.pageInfo.tatal_list_count = 0
        }
      })
    },
    getMyPartnerList (memId, type, item) {
      console.log(memId, type, item)
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      retailMyPartnerDirectList(params, type).then(res => {
        console.log('retailMyPartnerDirectList : ', item, params, res.data)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = null
            item.children = list

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.loadSubList(memId, 1, type)
            this.loadInfo(memId)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    }
    // getHierarchyList (memId, type, item) {
    //   this.emitter.emit('Loading', true)
    //   const params = {
    //     memId: memId
    //   }
    //   return getPartnerHierarchyV2(params).then(res => {
    //     const result = res.data
    //     console.log('getPartnerHierarchyV2 : ', params, result)
    //     const list = result.data.hierarchy
    //     if (type === 'sub') {
    //       item.children = list
    //       item.open = !item.open
    //       if (this.selectedLi === memId) {
    //         this.selectedLi = memId
    //       } else {
    //         this.selectedLi = memId
    //       }
    //
    //       // this.loadSubList(memId, 1, type)
    //     } else {
    //       this.partnerList = list
    //     }
    //
    //     this.emitter.emit('Loading', false)
    //
    //     return true
    //   })
    // }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style scoped>
.w100 {width: 100%;}
.partnerTop {display: flex;align-items: center;justify-content: space-between;margin-bottom: 17px;}
.select {display: flex;align-items: center;gap: 3px;}
.select a {display: flex;align-items: center;justify-content: center;height: 30px;padding: 0 15px;border-radius: 3px;border: solid 1px #b1b8bb;box-sizing: border-box;background-color: #fff;font-size: 15px;color: #6b737c;cursor: pointer;}
.select a.on, .select a:hover {color: #fff;border: solid 1px #505459;background-color: #575b60;}

.partnerInfo {padding: 12px 10px 30px;margin-bottom: 30px;box-sizing: border-box;display: flex;flex-direction: column;gap: 30px;background-color: #fff;}
h4.title {font-size: 20px;font-weight: bold;letter-spacing: -1px;color: #000;margin-bottom: 10px;}
.partnerInfo ul {display: flex;flex-direction: column;gap: 12px;width: 273px;}
.partnerInfo ul li:first-child {font-size: 15px;font-weight: bold;color: #000;box-shadow: none;border: 0;}
.partnerInfo ul li {    display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7e7e7;
  box-sizing: border-box;
  background-color: #fff;font-weight: bold;color: #3b3b3b;}
.partnerInfo ul li em {display: flex;
    align-items: center;
    justify-content: space-between;height: 30px;
  letter-spacing: -0.75px;
  border-bottom: 1px solid #e7e7e7;padding: 0 13px;}
.partnerInfo ul li span {display: flex;
    align-items: center;
    justify-content: space-between;height: 46px;font-size: 13px;letter-spacing: -0.65px;padding: 0 13px;}
.number input {border: 0 !important;padding: 0 !important;font-size: 13px !important;}
.infoView {display: flex;align-items: center;justify-content: center;gap: 50px;}
.btnAdd {display: flex;align-items: center;margin-left: auto;margin-right: 50px;height: 27px;padding: 0 18px;border-radius: 4px;background-color: #363b72;color: #fff;font-size: 13px;cursor: pointer;}
.btn-select-wrap {display: flex;align-items: center;justify-content: center;}
.btn-select-wrap select {min-width: auto !important;height: 27px !important;border: solid 1px #c9c9c9 !important;padding: 0 0 0 5px !important;border-radius: 4px 0 0 4px !important;}
.btn-select-wrap .btnp {border-radius: 0 4px 4px 0 !important;}
.btnp {
  height: 27px;
  padding: 0px 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #8376d4;
  color: #fff;
  display: flex;
  align-items: center;cursor: pointer;
}
.flex-c {
  flex-direction: column;
}
.gap-2 {
  gap: 2px;
}
.subPT_1 li {
  position: relative;
}
.allBtn {
  background: #e50000;
  color: #fff;
  width: 57px;
  height: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #000;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.moveWrap2 {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;z-index: 5;font-family: 'NanumGothic';box-shadow: 0 0 10px 1px #ddd;}
.moveWrap2 .close {position: absolute;right: 50px;top: 18px; cursor: pointer;}
.moveWrap2 .close img {height: 35px;}
.moveWraphead { padding: 20px 50px; background:#575C68; color: #fff; font-size: 18px; }
.moveWrapbody { padding: 0 50px 20px; }
.moveWrap2 h4 {font-size: 16px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #575C68;}
.moveWrap2 table {width: 800px;text-align: center;}
.moveWrap2 th {height: 40px;background: #828793; border: solid 1px #DDDEE2;color: #fff;vertical-align: middle;}
.moveWrap2 table tr:nth-child(2) th { background:#EEF0F5; color: #575C68; }
.moveWrap2 .scroll table tr:nth-child(2) th { background:#828793; color: #fff;}
.moveWrap2 td {height: 40px;background: #FBFCFD;vertical-align: middle;border: solid 1px #EEF0F5;white-space: nowrap;color: #575C68;overflow: hidden;text-overflow: ellipsis;position: relative;}
.moveWrap2 input {width: 50px;border: 1px solid #0000F0;background: #fff;border-radius: 3px;color: #575C68;text-align: center;}
.moveWrap2 input.in {width: 90%;height: 28px;}
.moveWrap2 input.in.password {-webkit-text-security: disc;-moz-webkit-text-security: disc;-moz-text-security: disc;}
.moveWrap2 .accountInfo input.in { width: 54%; }
.moveWrap2 .accountInfo input.in.w45w {width: 45%;}
.moveWrap2 select.in {width: 80px;height: 28px;border: 1px solid #60452a;background: #fff;color: #575C68;margin-right: 5px;}
.moveWrap2 p {margin-top: 25px;color: #2A40B9;font-size: 12px;text-align: right;}
.moveWrap2 a.btn {margin-top: 25px;background: #2F4DF2;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
/* .tabmenu_make { width: } */
.tabmenu_make > ul {display: flex; gap: 10px; }
.tabmenu_make > ul li {border: 1px solid; border-radius: 10px; padding: 8px 15px; cursor: pointer; }
.tabmenu_make > ul li.current {background: #fff;color: #575C68;}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
  .infoView {flex-wrap: wrap;gap: 15px;}
  .infoView .number input {width: calc(100% - 58px);}
  .partnerInfo ul {width: 48%;}
  .moveWrap2 {width: 90%;}
  .partnerTop {flex-direction: column;width: 100%;}
  .select {width: 100%;}
  .select a {width: 100%;padding: 0 5px;font-size: 12px;}
}
@media (max-width: 500px) {
  .infoView {flex-direction: column;}
  .partnerInfo ul {flex-direction: row;flex-wrap: wrap;gap: 5px;width: 100%;}
  .partnerInfo ul li:first-child {width: 100%;text-align: left;}
  .partnerInfo ul li + li {width: calc(50% - 6px);}
  .partnerInfo ul li em {height: 25px;font-size: 13px;}
  .partnerInfo ul li span {height: 40px;padding: 0 6px;font-size: 12px;justify-content: center;}
}
</style>
