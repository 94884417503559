<template>
  <div class="w900">
    <div class="boardWrap">
      <board-filter @onSearch="onSearch"/>
      <div class="strTablescr">
        <div class="strTablePC">
          <table class="strTablePT">
            <thead>
              <tr>
                <th>{{$t('front.board.type')}}</th>
                <th>{{$t('front.board.title')}}</th>
                <th>작성자</th>
                <th>{{$t('front.board.regDate')}}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in list" v-bind:key="item.boardIdx">
                <tr>
                  <td>{{$t('front.boardCategory.' + item.category)}}</td>
                  <td>
                    <a href="#" @click.prevent="onClickRead(item.boardIdx)">
                      {{item.title}}
                      <span class="nsicon rdbg ml5">N</span>
                    </a>
                  </td>
                  <td>{{ item.memName }}</td>
                  <td>{{dateFormatAll(item.regDate)}}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
       <div class="mt20">
          <pagination
              :pageNum="pageInfo.page"
              :pageSize="pageInfo.count_per_list"
              :totalCount="pageInfo.tatal_list_count"
              @goToPage="loadList"
          />
       </div>
     </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import BoardFilter from '@/components/common/BoardFilter'

export default {
  name: 'Notice',
  components: {
    Pagination,
    BoardFilter
  },
  data () {
    return {
      list: null,
      boardType: 'nt',
      searchParam: {
        searchOption: '',
        searchValue: ''
      }
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text

      this.loadList()
    },
    async loadList (page) {
      this.pageInfo.page = page || 1
      const params = {
        boardType: this.boardType
      }

      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }

      console.log(params)

      const result = await this.getBoardList(params, this.pageInfo.page)
      result.list.forEach(item => {
        item.isContentOpen = false
      })
      this.list = result.list
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'noticeRead', params: { boardIdx: id } })
    }
  }
}
</script>

<style scoped>
a{color:#121212;}
</style>
<style src="@/styles/striNew.css"></style>
<style scoped>
.applibtns{gap:20px;}
@media (max-width: 1000px) {
  .strTablePTM li {width: 100%;gap: 10px;}
  .ocmsg {margin: 0;}
}
</style>
