<template>
  <div class="datesearch datePt">
    <input type="date" :value="model.startDate" @change="onChangeStartDate"/>
    <input type="date" :value="model.endDate" @change="onChangeEndDate"/>
    <button class="dateBtn" @click="onSearch">검색</button>
  </div>
</template>

<script>
import { getDateStr, getSubDaysDate } from '@/libs/utils'

export default {
  name: 'DateFilter',
  props: {
    startDate: {
      type: String,
      default: getDateStr(getSubDaysDate(new Date(), 7))
    },
    endDate: {
      type: String,
      default: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
    },
    retail: {
      type: Boolean,
      default: false
    },
    defaultDay: {
      type: Number,
      default: 7
    },
    isOldYn: {
      type: Boolean,
      default: false
    },
    oldYn: {
      type: String,
      default: 'N'
    },
    id: {
      type: String,
      default: new Date().getTime()
    }
  },
  data () {
    return {
      model: {
        startDate: null,
        endDate: null
      },
      memId: '',
      day: 7
    }
  },
  created () {
    this.day = this.defaultDay
    this.model.startDate = this.startDate.split(' ')[0]
    this.model.endDate = this.endDate.split(' ')[0]
    if (this.$route.name.indexOf('bet') > -1) {
      this.day = 0
      this.model.startDate = getDateStr(getSubDaysDate(new Date(), this.day))
    }
  },
  methods: {
    setOldYn (value) {
      this.$emit('setOldYn', value.target.checked ? 'Y' : 'N')
    },
    onSearch (value) {
      let result = false
      if (value || value === 0) {
        result = true
      }
      this.$emit('search', result)
    },
    onChangeStartDate (event) {
      this.model.startDate = event.target.value

      const model = {
        startDate: this.model.startDate + ' 00:00:00',
        endDate: this.model.endDate + ' 23:59:59'
      }
      if (this.retail) {
        model.memId = this.memId
      }
      this.$emit('update', model)
      this.onSearch()
    },
    onChangeEndDate (event) {
      this.model.endDate = event.target.value
      const model = {
        startDate: this.model.startDate + ' 00:00:00',
        endDate: this.model.endDate + ' 23:59:59'
      }
      if (this.retail) {
        model.memId = this.memId
      }
      this.$emit('update', model)
      this.onSearch()
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped>
.datesearch.datePt {background: none; gap: 0; justify-content: flex-end;}
.datesearch.datePt input[type="date"] {height: 42px !important; width: 150px;  border-radius: 0; border: solid 1px #cfcfcf; font-size: 15px !important; position: relative; text-align: center; cursor: pointer;}
.datesearch.datePt input:first-child {border-right: 0;}
.datesearch.datePt input[type="date"]::-webkit-calendar-picker-indicator {background: none; position: absolute; height: 100%; width: 100%; z-index: 0; left: 0; top: 0; cursor: pointer; }
.datesearch.datePt .dateBtn {background-color: #d98f00;color: #fff;border-radius: 5px;border: solid 1px #707070; padding: 6px 15px 5px 13px; margin-left: 10px;}
@media screen and (max-width: 840px) {
  .datesearch.datePt input[type="date"] {width: 130px !important;}
}
</style>
