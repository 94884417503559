<template>
  <div class="tab-content">
      <div class="pagenamPT">
        <h3 class="pagename2">{{PARTNER_LEVEL_NAME[partnerLevel]}} 목록</h3>
      </div>
      <div class="partnerTop">
        <div class="select">
          <a @click="partnerListOrder = 'JOIN_DESC'">생성순</a>
          <a @click="partnerListOrder = 'ID_DESC'">아이디 순</a>
          <a @click="partnerListOrder = 'CASH_DESC'">보유머니 순</a>
          <a @click="partnerListOrder = 'POINT_DESC'">보유롤링금 순</a>
        </div>
        <div class="fx-center-end">
          <select class="selectBox" v-model="searchType">
            <option :value="'id'">ID</option>
            <option :value="'nickname'">닉네임</option>
          </select>
          <input type="text" class="input" v-model="searchValue"/>
          <button class="dateBtn" @click="loadSubList(1)">검색</button>
        </div>
      </div>
      <div class="strTableWrap">
        <div class="strTablescr">
            <div class="strTablePC">
              <table class="strTablePT">
                <colgroup>
                  <col style="width:9%;">
                  <col style="width:6%;">
                  <col style="width:10%;">
                  <col style="width:9%;">
                  <col style="width:6%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                  <col style="width:6%;">
                  <col style="width:9%;">
                  <col style="width:9%;">
                </colgroup>
                <thead>
                  <tr>
                    <th>아이디<br />닉네임</th>
                    <th>파트너등급</th>
                    <th>소속</th>
                    <th>보유머니<br />보유포인트</th>
                    <th>입출금</th>
                    <th>롤링 % (카)<br />롤링 % (슬)</th>
                    <th>루징 % (카)<br />루징 % (슬)</th>
                    <th>총보유머니<br />총보유롤링금</th>
                    <th>하부 수</th>
                    <th>회원 수</th>
                    <th>등록일시</th>
                    <th>상세정보</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="partnerSubList && partnerSubList.length > 0">
                    <tr v-for="(partner, index) in partnerSubList" :key="partner.memId">
                      <td>
                        {{partner.memId}}<br />
                        <span class="nick">{{partner.memNick}}</span>
                      </td>
                      <td>
                        <span class="box" :class="partner.partnerLevel">{{partner.partnerLevelName}}</span>
                      </td>
                      <td>
                        <div class="topwrap" v-if="partner.topUserList && partner.topUserList.length > 0 && partner.topUserList !== '0'">
                          <select class="h20 upperUser">
                            <option v-for="pt in partner.topUserList" :key="pt.recommenderId">
                              <span>[{{pt.partnerLevelName}}] </span>
                              <span>{{pt.recommenderId}}</span>
                            </option>
                          </select>
                          <i class="icon">+</i>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap">
                          <span>{{thousand(partner.cashAmt || 0)}}</span>
                          <span>{{thousand(partner.pointAmt || 0)}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <a class="inout-btn btn-green" @click="onOpenPay(partner, index)">지급회수</a>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <span class="rate-font roboto">{{partner.casinoRate}}%/{{partner.slotRate}}%</span>
                          <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <span class="rate-font roboto">{{partner.casinoLosing}}%/{{partner.slotLosing}}%</span>
                          <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap">
                          <span>{{thousand(partner.totalBalance.totalCashAmt || 0)}}</span>
                          <span class="plusAmt minusAmt">{{thousand(partner.totalBalance.totalPointAmt || 0)}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap f-center">
                          <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                            <template v-if="pt.partnerLevel !== 'NORMAL'">
                              <span>{{pt.partnerLevelName}} : {{thousand(pt.cnt || 0)}}</span>
                            </template>
                          </template>
                        </div>
                      </td>
                      <td>
                        <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                          <template v-if="pt.partnerLevel == 'NORMAL'">
                            <span>{{thousand(pt.cnt || 0)}}</span>
                          </template>
                        </template>
                      </td>
                      <td>{{partner.joinDate.replace('T', ' ')}}</td>
                      <td>
                        <div class="btn-input-wrap">
                          <a class="btnp" @click="goToDetail(partner.memId)">파트너정보 / 수정</a>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="12">하부 파트너가 없습니다.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="partnerSubList && partnerSubList.length > 0">
                  <ul v-for="(partner, index) in partnerSubList" :key="partner.memId">
                    <li>
                      <em>아이디<br />닉네임</em>
                      <div>
                        {{partner.memId}}<br />
                        <span class="nick">{{partner.memNick}}</span>
                      </div>
                    </li>
                    <li>
                      <em>파트너등급</em>
                      <div>
                        <span class="box" :class="partner.partnerLevel">{{partner.partnerLevelName}}</span>
                      </div>
                    </li>
                    <li>
                      <em>소속</em>
                      <div class="topwrap" v-if="partner.topUserList && partner.topUserList.length > 0 && partner.topUserList !== '0'">
                        <select class="h20 upperUser mw-auto">
                          <option v-for="pt in partner.topUserList" :key="pt.recommenderId">
                            <span>[{{pt.partnerLevelName}}] </span>
                            <span>{{pt.recommenderId}}</span>
                          </option>
                        </select>
                        <i class="icon">+</i>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </li>
                    <li>
                      <em>보유머니<br />보유포인트</em>
                      <div>
                        <span>{{thousand(partner.cashAmt || 0)}}</span><br />
                        <span>{{thousand(partner.pointAmt || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <em>입출금</em>
                      <div>
                        <a class="inout-btn" @click="onOpenPay(partner, index)">지급회수</a>
                      </div>
                    </li>
                    <li>
                      <em>롤링 % (카)<br />롤링 % (슬)</em>
                      <div class="m-table-r">
                        <span class="rate-font">{{partner.casinoRate}}%/{{partner.slotRate}}%</span>
                        <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                      </div>
                    </li>
                    <li>
                      <em>루징 % (카)<br />루징 % (슬)</em>
                      <div class="m-table-r">
                        <span class="rate-font">{{partner.casinoLosing}}%/{{partner.slotLosing}}%</span>
                        <a class="plus-btn" @click="onOpenRate(partner, index)"></a>
                      </div>
                    </li>
                    <li>
                      <em>총보유머니<br />총보유롤링금</em>
                      <div>
                        <span>{{thousand(partner.totalBalance.totalCashAmt || 0)}}</span><br />
                        <span class="plusAmt minusAmt">{{thousand(partner.totalBalance.totalPointAmt || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <!--                      <em>지사 수<br />총판 수<br />매장 수</em>-->
                      <!--                      <div>-->
                      <!--                        <span>10,000,000</span>-->
                      <!--                        <span>10,000,000</span>-->
                      <!--                        <span>10,000,000</span>-->
                      <!--                      </div>-->
                      <em>하부수</em>
                      <div>
                        <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                          <template v-if="pt.partnerLevel !== 'NORMAL'">
                            <span>{{pt.partnerLevelName}} : {{thousand(pt.cnt || 0)}}</span>
                          </template>
                        </template>
                      </div>
                    </li>
                    <li>
                      <em>회원 수</em>
                      <template v-for="pt in partner.countInfo" :key="pt.partnerLevel">
                        <template v-if="pt.partnerLevel == 'NORMAL'">
                          <div>{{thousand(pt.cnt || 0)}}</div>
                        </template>
                      </template>
                    </li>
                    <li>
                      <em>등록일시</em>
                      <div>{{partner.joinDate.replace('T', ' ')}}</div>
                    </li>
                    <li>
                      <em>상세정보</em>
                      <div><a class="btnp" @click="goToDetail(partner.memId)">파트너정보 / 수정</a></div>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
        </div>
      </div>
    <pagination v-if="pageInfo"
                :pageNum="pageInfo.page"
                :pageSize="pageInfo.count_per_list"
                :totalCount="pageInfo.tatal_list_count"
                @goToPage="loadSubList"
    />
      <transition name="fade">
        <RateTable v-if="RateOpen" :selectMem="selectMem" :selectIdx="selectIdx"  @close="onCloseRate"/>
      </transition>
      <transition name="fade">
        <Paymodal v-if="PayMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePay"/>
      </transition>
  </div>
</template>

<script>
import RateTable from '@/components/common/RateTable.vue'
import Paymodal from '@/components/common/Pay.vue'
import Pagination from '@/components/ui/Pagination.vue'
import { mapState } from 'vuex'
import { getPartnerListV2 } from '@/api/v2/partner'
import { thousand } from '@/libs/utils'
import { PARTNER_LEVEL_NAME, PARTNER_LEVEL_ORDER } from '@/libs/constants'
export default {
  name: 'partnerListLevel',
  components: { Pagination, RateTable, Paymodal },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    PARTNER_LEVEL_NAME () {
      return PARTNER_LEVEL_NAME
    }
  },
  data () {
    return {
      RateOpen: false,
      PayMopen: false,
      selectMem: null,
      selectIdx: null,
      selectedLi: null,
      partnerLevel: null,
      partnerSubList: null,
      partnerListOrder: 'JOIN_DESC',
      searchType: 'id',
      searchValue: ''
    }
  },
  async created () {
    const name = this.$route.name
    this.partnerLevel = name.replace('partnerListLevel', '')
    await this.loadSubList(1)
  },
  watch: {
    partnerListOrder () {
      this.loadSubList(1)
    }
  },
  methods: {
    goToDetail (memId) {
      // this.goPageByName('partnerDetail', { memId: memId })
      this.detailOpen('partner', memId)
    },
    onCloseRate () {
      this.RateOpen = false
      this.selectMem = null
      this.selectIdx = null
    },
    onClosePay (newCash, idx) {
      if (newCash !== null && newCash !== undefined) {
        this.partnerSubList[idx].cashAmt = newCash
      }
      this.PayMopen = false
      this.selectMem = null
      this.selectIdx = null
    },
    onOpenPay (selectMem, selectIdx) {
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      this.selectIdx = selectIdx
      this.PayMopen = true
    },
    onOpenRate (selectMem, selectIdx) {
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      this.selectIdx = selectIdx
      this.RateOpen = true
    },
    async loadSubList (page) {
      if (!page) {
        page = this.pageInfo.page
      } else {
        this.pageInfo.page = page
      }
      this.emitter.emit('Loading', true)

      const params = {
        memId: this.userData.memId,
        targetLevel: this.partnerLevel,
        searchType: this.searchType,
        searchValue: this.searchValue,
        orderStr: this.partnerListOrder,
        page: page,
        count_per_list: 40
      }

      console.log(params)

      await getPartnerListV2(params).then(res => {
        this.emitter.emit('Loading', false)
        console.log('getPartnerListV2 sub : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerSubList = result.data.list

          this.partnerSubList.forEach(item => {
            const topUserList = []
            if (item.topUserList) {
              item.topUserList.forEach(pt => {
                if (this.userData.partnerLevelOrder <= PARTNER_LEVEL_ORDER[pt.partnerLevel]) {
                  topUserList.push(pt)
                }
              })
            }
            item.topUserList = topUserList
          })
        }

        if (result.data.pageInfo) {
          this.pageInfo = result.data.pageInfo
        } else {
          this.pageInfo.page = 1
          this.pageInfo.tatal_list_count = 0
        }
      })
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style scoped>
.partnerTop {display: flex;align-items: center;justify-content: space-between;margin-bottom: 17px;}
.fx-center-end {display: flex;align-items: center;gap: 10px;}
.selectBox {
  height: 42px !important;
  padding: 12px 11px;
  box-sizing: border-box;
  border: solid 1px #cfcfcf !important;
  font-size: 15px !important;
}
.input {
  width: 300px;
  height: 42px !important;
  padding: 12px 11px !important;
  box-sizing: border-box;
  border: solid 1px #cfcfcf !important;
  background-color: #fff;
  font-size: 15px !important;
}
.select {display: flex;align-items: center;gap: 3px;}
.select a {display: flex;align-items: center;justify-content: center;height: 30px;padding: 0 15px;border-radius: 3px;border: solid 1px #b1b8bb;box-sizing: border-box;background-color: #fff;font-size: 15px;color: #6b737c;cursor: pointer;}
.select a.on, .select a:hover {color: #fff;border: solid 1px #505459;background-color: #575b60;}

.partnerInfo {padding: 12px 10px 30px;margin-bottom: 30px;box-sizing: border-box;display: flex;flex-direction: column;gap: 30px;}
h4.title {font-size: 20px;font-weight: bold;letter-spacing: -1px;color: #000;margin-bottom: 10px;}
.partnerInfo ul {display: flex;flex-direction: column;gap: 12px;width: 273px;}
.partnerInfo ul li:first-child {font-size: 15px;font-weight: bold;color: #000;box-shadow: none;border: 0;}
.partnerInfo ul li {    display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7e7e7;
  box-sizing: border-box;
  background-color: #fff;font-weight: bold;color: #3b3b3b;}
.partnerInfo ul li em {display: flex;
    align-items: center;
    justify-content: space-between;height: 30px;
  letter-spacing: -0.75px;
  border-bottom: 1px solid #e7e7e7;padding: 0 13px;}
.partnerInfo ul li span {display: flex;
    align-items: center;
    justify-content: space-between;height: 46px;font-size: 13px;letter-spacing: -0.65px;padding: 0 13px;}
.infoView {display: flex;align-items: center;justify-content: center;gap: 50px;}
.btnAdd {display: flex;align-items: center;margin-left: auto;margin-right: 50px;height: 27px;padding: 0 18px;border-radius: 4px;background-color: #363b72;color: #fff;font-size: 13px;}
.btn-select-wrap {display: flex;align-items: center;justify-content: center;}
.btn-select-wrap select {min-width: auto !important;height: 27px !important;border: solid 1px #c9c9c9 !important;padding: 0 0 0 5px !important;border-radius: 4px 0 0 4px !important;}
.btn-select-wrap .btnp {border-radius: 0 4px 4px 0 !important;}
.btnp {
  height: 27px;
  padding: 0px 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #8376d4;
  color: #fff;
  display: flex;
  align-items: center;cursor: pointer;
}
.flex-c {
  flex-direction: column;
}
.gap-2 {
  gap: 2px;
}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
  .partnerTop {flex-direction: column;gap: 15px;}
  .select {width: 100%;}
  .select a {width: 100%;padding: 0 5px;font-size: 12px;}
  .dateBtn {margin: 0;}
  .fx-center-end .input {width: calc(100% - 165px);}
}
.subPT_1 li {
  position: relative;
}
.allBtn {
  background: #e50000;
  color: #fff;
  width: 57px;
  height: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #000;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
</style>
