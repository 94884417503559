import httpClient from '@/libs/http-client'

export function getPartnerHierarchyV2 (params) {
  const url = '/api/v2/retail/hierarchy'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getPartnerListV2 (params) {
  const url = '/api/v2/retail/lowerPartnerList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getPartnerInfoV2 (params) {
  const url = '/api/v2/retail/partnerInfo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function updatePartnerInfoPhoneV2 (params) {
  const url = '/api/v2/retail/updMemPhone'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerJoinV2 (params) {
  const url = '/api/v2/retail/createPartner'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerDetailV2 (params) {
  const url = '/api/v2/retail/detail/info'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function savePartnerDetailV2 (params) {
  const url = '/api/v2/retail/detail/info/save'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerDetailRateV2 (params) {
  const url = '/api/v2/retail/detail/rateInfo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function savePartnerDetailRateV2 (params) {
  const url = '/api/v2/retail/detail/rateInfo/save'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerDetailMultipleRateV2 (params) {
  const url = '/api/v2/retail/detail/multipleRateInfo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function savePartnerDetailMultipleRateV2 (params) {
  const url = '/api/v2/retail/detail/multipleRateInfo/save'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerDetailLoginV2 (params) {
  const url = '/api/v2/retail/detail/loginLog'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerDetailMsgV2 (params) {
  const url = '/api/v2/retail/detail/msgLog'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerDetailCashLogV2 (params) {
  const url = '/api/v2/retail/detail/cashLog'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerDetailRollingLogV2 (params) {
  const url = '/api/v2/retail/detail/rollingLog'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
