<template>
  <div class="w900" v-if="model">
    <div class="board">
      <div class="boardTitle">
        <div class="boardrd first">
         <span class="title">{{model.title}}</span>
        </div>
        <div class="boardrd">
          <span class="date"><img src="" />{{dateFormatForBoard(model.regDate)}}</span>
        </div>
      </div>
      <div class="boardbox" v-html="model.content">

      </div>
    </div>
    <div class="applibtns">
      <a v-if="prevId" @click="onClickPrevNext('prev')">{{$t('front.board.prev')}}</a>
      <a @click="onClickList">{{$t('front.board.list')}}</a>
      <a v-if="nextId" @click="onClickPrevNext('next')">{{$t('front.board.next')}}</a>
    </div>
  </div>
</template>

<script>

import { getBoardCookie } from '@/libs/auth-helper'

export default {
  name: 'NoticeRead',
  data () {
    return {
      boardType: 'nt',
      boardIdx: null,
      model: null,
      prevId: null,
      nextId: null
    }
  },
  created () {
    this.boardIdx = this.$route.params.boardIdx
    this.loadBoardDetail(this.boardIdx)
    this.idxList = getBoardCookie().split(',')

    if (this.idxList.length > 0) {
      for (let i = 0, iLen = this.idxList.length; i < iLen; i++) {
        if (this.idxList[i] === this.boardIdx.toString()) {
          if (this.idxList[i - 1]) {
            this.prevId = this.idxList[i - 1]
          }
          if (this.idxList[i + 1]) {
            this.nextId = this.idxList[i + 1]
          }
        }
      }
    }
  },
  methods: {
    async loadBoardDetail (boardIdx) {
      const params = {
        boardIdx: boardIdx,
        boardType: this.boardType
      }
      this.model = await this.getBoardDetail(params)
      this.model.content = this.model.content.replace(/\n/g, '<br>')
    },
    onClickPrevNext (type) {
      let id = this.prevId
      if (type === 'next') {
        id = this.nextId
      }
      this.$router.push({ name: 'noticeRead', params: { boardIdx: id } })
    },
    onClickList () {
      this.$router.push({ name: 'noticeList' })
    }
  }
}
</script>
<style scoped src="@/styles/striNew.css"></style>
