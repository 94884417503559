import { createWebHistory, createRouter } from 'vue-router'
import { DOCUMENT_TITLE_PREFIX } from '@/libs/constants'

import { getLangCookie, tokenCheck, getSiteId } from '@/libs/auth-helper'

import store from '../store'

const routes = [
  {
    path: '/',
    name: 'blank',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      layout: 'empty',
      guestOnly: true
    }
  },
  {
    name: 'partnerLogin',
    path: '/:prefix/login',
    component: () => import('@/views/member'),
    meta: {
      layout: 'default',
      text: '파트너 페이지',
      gnb: false,
      guestOnly: true
    }
  },
  {
    path: '/:prefix',
    name: 'main',
    component: () => import('@/views'),
    meta: {
      layout: 'empty',
      guestOnly: true
    },
    redirect: {
      name: 'partnerMain'
    },
    children: [
      {
        name: 'partner',
        path: '/:prefix/main',
        component: () => import('@/views/member/partner/index.vue'),
        redirect: {
          name: 'partnerMain'
        },
        meta: {
          layout: 'default',
          text: '메인',
          gnb: false
        },
        children: [
          {
            name: 'partnerMain',
            path: '/:prefix/partnerMain',
            component: () => import('@/views/member/partner/main.vue'),
            meta: {
              layout: 'default',
              text: '파트너 메인',
              gnb: false
            }
          },
          {
            name: 'partnerList',
            path: '/:prefix/partnerList',
            component: () => import('@/views/member/partner/partnerList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 리스트',
              gnb: false
            }
          },
          {
            name: 'partnerListLevelPTN_1',
            path: '/:prefix/partnerListLevelPTN_1',
            component: () => import('@/views/member/partner/partnerListLevel.vue'),
            meta: {
              layout: 'default',
              text: '파트너 리스트 레벨',
              gnb: false
            }
          },
          {
            name: 'partnerListLevelPTN_2',
            path: '/:prefix/partnerListLevelPTN_2',
            component: () => import('@/views/member/partner/partnerListLevel.vue'),
            meta: {
              layout: 'default',
              text: '파트너 리스트 레벨',
              gnb: false
            }
          },
          {
            name: 'partnerListLevelPTN_3',
            path: '/:prefix/partnerListLevelPTN_3',
            component: () => import('@/views/member/partner/partnerListLevel.vue'),
            meta: {
              layout: 'default',
              text: '파트너 리스트 레벨',
              gnb: false
            }
          },
          {
            name: 'partnerListLevelPTN_4',
            path: '/:prefix/partnerListLevelPTN_4',
            component: () => import('@/views/member/partner/partnerListLevel.vue'),
            meta: {
              layout: 'default',
              text: '파트너 리스트 레벨',
              gnb: false
            }
          },
          {
            name: 'partnerListLevelPTN_5',
            path: '/:prefix/partnerListLevelPTN_5',
            component: () => import('@/views/member/partner/partnerListLevel.vue'),
            meta: {
              layout: 'default',
              text: '파트너 리스트 레벨',
              gnb: false
            }
          },
          {
            name: 'partnerDetail',
            path: '/:prefix/partnerDetail',
            component: () => import('@/views/member/partner/partnerDetail.vue'),
            meta: {
              layout: 'default',
              text: '파트너 상세',
              gnb: false
            }
          },
          {
            name: 'partnerUserList',
            path: '/:prefix/userList',
            component: () => import('@/views/member/partner/userList.vue'),
            meta: {
              layout: 'default',
              text: '회원목록',
              gnb: false
            }
          },
          {
            name: 'userDetail',
            path: '/:prefix/userDetail',
            component: () => import('@/views/member/partner/userDetail.vue'),
            meta: {
              layout: 'default',
              text: '회원상세',
              gnb: false
            }
          },
          {
            name: 'partnerCashList',
            path: '/:prefix/cashList',
            component: () => import('@/views/member/partner/cashList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'cashListMy',
            path: '/:prefix/cashListMy',
            component: () => import('@/views/member/partner/cashListMy.vue'),
            meta: {
              layout: 'default',
              text: '보유머니변동내역',
              gnb: false
            }
          },
          {
            name: 'partnerBettingList',
            path: '/:prefix/bettingList',
            component: () => import('@/views/member/partner/bettingList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerBettingListSport',
            path: '/:prefix/bettingListSport',
            component: () => import('@/views/member/partner/bettingListSport.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerList2',
            path: '/:prefix/partnerList2',
            component: () => import('@/views/member/partner/partnerList2.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerRate',
            path: '/:prefix/partnerRate',
            component: () => import('@/views/member/partner/partnerRate.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'pointList',
            path: '/:prefix/pointList',
            component: () => import('@/views/member/partner/point/pointList.vue'),
            meta: {
              layout: 'default',
              text: '포인트적립내역',
              gnb: false
            }
          },
          {
            name: 'partnerCalculationList',
            path: '/:prefix/partnerCalculationList',
            component: () => import('@/views/member/partner/calculationList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerCalculationUserList',
            path: '/:prefix/partnerCalculationUserList',
            component: () => import('@/views/member/partner/calculationUserList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerMemberList',
            path: '/:prefix/partnerMemberList',
            component: () => import('@/views/member/partner/memberList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'onlineMemberList',
            path: '/:prefix/onlineMemberList',
            component: () => import('@/views/member/partner/onlineMemberList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerMoneyMoveList',
            path: '/:prefix/partnerMoneyMoveList',
            component: () => import('@/views/member/partner/moneyMove.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'subMemberList',
            path: '/:prefix/subMemberList',
            component: () => import('@/views/member/partner/subMemberList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'charge',
            path: '/:prefix/charge',
            component: () => import('@/views/member/partner/cash/charge.vue'),
            meta: {
              layout: 'default',
              text: '입금',
              gnb: false
            }
          },
          {
            name: 'exchange',
            path: '/:prefix/exchange',
            component: () => import('@/views/member/partner/cash/exchange.vue'),
            meta: {
              layout: 'default',
              text: '출금',
              gnb: false
            }
          },
          {
            name: 'point',
            path: '/:prefix/point',
            component: () => import('@/views/member/partner/point/pointListU.vue'),
            meta: {
              layout: 'default',
              text: '포인트',
              gnb: false
            }
          },
          {
            name: 'pointuse',
            path: '/:prefix/pointuse',
            component: () => import('@/views/member/partner/point/pointUse.vue'),
            meta: {
              layout: 'default',
              text: '포인트',
              gnb: false
            }
          },
          {
            name: 'pointuselist',
            path: '/:prefix/pointuselist',
            component: () => import('@/views/member/partner/point/pointUseList.vue'),
            meta: {
              layout: 'default',
              text: '포인트',
              gnb: false
            }
          },
          {
            name: 'subpartnerPointList',
            path: '/:prefix/subpartnerPointList',
            component: () => import('@/views/member/partner/subpartnerPointList.vue'),
            meta: {
              layout: 'default',
              text: '하부파트너 포인트적립내역',
              gnb: false
            }
          },
          {
            name: 'qna',
            path: '/:prefix/qna',
            component: () => import('@/views/member/partner/board/qna.vue'),
            meta: {
              layout: 'default',
              text: '문의',
              gnb: false
            }
          },
          {
            name: 'qnaread',
            path: '/:prefix/qnaread/:boardIdx',
            component: () => import('@/views/member/partner/board/qnaRead.vue'),
            meta: {
              layout: 'default',
              text: '문의',
              gnb: false
            }
          },
          {
            name: 'noticeList',
            path: '/:prefix/noticeList',
            component: () => import('@/views/member/partner/board/noticeList.vue'),
            meta: {
              layout: 'default',
              text: '공지사항목록',
              gnb: false
            }
          },
          {
            name: 'noticeRead',
            path: '/:prefix/noticeRead/:boardIdx',
            component: () => import('@/views/member/partner/board/noticeRead.vue'),
            meta: {
              layout: 'default',
              text: '공지사항',
              gnb: false
            }
          },
          {
            name: 'qnaWrite',
            path: '/:prefix/qnawrite',
            component: () => import('@/views/member/partner/board/qnaWrite.vue'),
            meta: {
              layout: 'default',
              text: '문의작성',
              gnb: false
            }
          },
          {
            name: 'msg',
            path: '/:prefix/msg',
            component: () => import('@/views/member/partner/board/msg.vue'),
            meta: {
              layout: 'default',
              text: '메세지',
              gnb: false
            }
          },
          {
            name: 'msgread',
            path: '/:prefix/msgread/:msgIdx',
            component: () => import('@/views/member/partner/board/msgRead.vue'),
            meta: {
              layout: 'default',
              text: '메세지',
              gnb: false
            }
          },
          {
            name: 'infoEdit',
            path: '/:prefix/infoEdit',
            component: () => import('@/views/member/partner/info.vue'),
            meta: {
              layout: 'default',
              text: '회원정보수정',
              gnb: false
            }
          },
          {
            name: 'statAllUser',
            path: '/:prefix/statisticAllUsers',
            component: () => import('@/views/member/partner/statistic/allUsers.vue'),
            meta: {
              layout: 'default',
              text: '전체 사용자별 게임기록 통계',
              gnb: false
            }
          },
          {
            name: 'statAllGame',
            path: '/:prefix/statisticAllGames',
            component: () => import('@/views/member/partner/statistic/allGames.vue'),
            meta: {
              layout: 'default',
              text: '전체 게임별 게임기록 통계',
              gnb: false
            }
          },
          {
            name: 'statDateAgent',
            path: '/:prefix/statisticDateAgent',
            component: () => import('@/views/member/partner/statistic/dateAgent.vue'),
            meta: {
              layout: 'default',
              text: '일자별 매장별 게임기록 통계',
              gnb: false
            }
          },
          {
            name: 'statDateUsers',
            path: '/:prefix/statisticDateUsers',
            component: () => import('@/views/member/partner/statistic/dateUsers.vue'),
            meta: {
              layout: 'default',
              text: '일자별 사용자별 게임기록 통계',
              gnb: false
            }
          },
          {
            name: 'statDateGames',
            path: '/:prefix/statisticDateGames',
            component: () => import('@/views/member/partner/statistic/dateGames.vue'),
            meta: {
              layout: 'default',
              text: '일자별 게임별 게임기록 통계',
              gnb: false
            }
          },
          {
            name: 'statDateBet',
            path: '/:prefix/statisticDateBet',
            component: () => import('@/views/member/partner/statistic/dateBet.vue'),
            meta: {
              layout: 'default',
              text: '일자별 베팅현황',
              gnb: false
            }
          },
          {
            name: 'bettingslot',
            path: '/:prefix/bettingslot',
            component: () => import('@/views/member/partner/betting/betAll.vue'),
            meta: {
              layout: 'default',
              text: '베팅현황(슬롯)',
              gnb: false
            }
          },
          {
            name: 'bettingcasino',
            path: '/:prefix/bettingcasino',
            component: () => import('@/views/member/partner/betting/betAll.vue'),
            meta: {
              layout: 'default',
              text: '베팅현황(카지노)',
              gnb: false
            }
          },
          {
            name: 'bettingtotal',
            path: '/:prefix/bettingtotal',
            component: () => import('@/views/member/partner/betting/betAll.vue'),
            meta: {
              layout: 'default',
              text: '베팅현황(전체)',
              gnb: false
            }
          },
          {
            name: 'userlist',
            path: '/:prefix/users/list',
            component: () => import('@/views/member/partner/users/list.vue'),
            meta: {
              layout: 'default',
              text: '회원목록',
              gnb: false
            }
          },
          {
            name: 'onlinelist',
            path: '/:prefix/userOnlinelist',
            component: () => import('@/views/member/partner/users/onlinelist.vue'),
            meta: {
              layout: 'default',
              text: '접속회원목록',
              gnb: false
            }
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'dashboard'
    }
  }
]

const router = createRouter({
  history: createWebHistory(), // 2.x대를 설치하시면 작동을 안합니다.
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 })
      }, 0)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  const title = DOCUMENT_TITLE_PREFIX

  const prefix = to.params.prefix
  const siteIdInfo = getSiteId(prefix)
  if (!siteIdInfo || !siteIdInfo.siteId) {
    location.href = '/' + prefix
  }
  await store.dispatch('storeSiteIdInfo', siteIdInfo)

  const result = await tokenCheck()
  if (to.meta.guestOnly || result) {
    next()
  } else {
    const lang = getLangCookie()
    let msg = '로그인 이후 이용 가능합니다.'
    if (lang !== 'ko') {
      msg = 'Please Log-in'
    }
    alert(msg)
    location.href = '/' + prefix
  }

  document.title = title
})

export default router
