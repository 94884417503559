<template>
  <div class="tab-content">
    <div class="pagenamPT">
      <h3 class="pagename2">전체 사용자별</h3>
      <date-filter-pt/>
    </div>
    <article class="content-wrap">
      <table class="statTable">
        <thead>
          <tr>
            <th>파트너 ID</th>
            <th>사용자 ID</th>
            <th>닉네임</th>
            <th class="text-right">베팅금</th>
            <th class="text-right">당첨금</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>spe0004</td>
            <td>sp01</td>
            <td>회원01</td>
            <td class="text-right">19,415,715</td>
            <td class="text-right">17,960,451</td>
            <td>-</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3"></td>
            <td class="text-right">19,415,715</td>
            <td class="text-right">17,960,451</td>
            <td>-</td>
          </tr>
        </tfoot>
      </table>
    </article>
  </div>
</template>
<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
export default {
  name: 'DateBet',
  components: { DateFilterPt },
  data () {
    return {
    }
  }
}
</script>
<style src="@/styles/pt.css"></style>
