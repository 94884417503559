<template>
  <div class="tab-content">
    <div class="pagenamPT">
      <h3 class="pagename2">일자별 게임별</h3>
      <date-filter-pt/>
    </div>
    <article class="content-wrap">
      <div class="strTablescr">
        <div class="strTablePC">
          <table class="statTable">
            <thead>
              <tr>
                <th>공급사</th>
                <th class="text-right">베팅금</th>
                <th class="text-right">당첨금</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Evolution</td>
                <td class="text-right">19,415,715</td>
                <td class="text-right">17,960,451</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Evolution</td>
                <td class="text-right">19,415,715</td>
                <td class="text-right">17,960,451</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Evolution</td>
                <td class="text-right">19,415,715</td>
                <td class="text-right">17,960,451</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Evolution</td>
                <td class="text-right">19,415,715</td>
                <td class="text-right">17,960,451</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Evolution</td>
                <td class="text-right">19,415,715</td>
                <td class="text-right">17,960,451</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Evolution</td>
                <td class="text-right">19,415,715</td>
                <td class="text-right">17,960,451</td>
                <td>-</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>합계</td>
                <td class="text-right">19,415,715</td>
                <td class="text-right">17,960,451</td>
                <td>-</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <ul class="total">
              <li>합계</li>
              <li>19,415,715</li>
              <li>17,960,451</li>
              <li>-</li>
            </ul>
            <ul>
              <li>
                <em>공급사</em>
                <div>Evolution</div>
              </li>
              <li>
                <em>베팅금</em>
                <div>19,415,715</div>
              </li>
              <li>
                <em>당첨금</em>
                <div>17,960,451</div>
              </li>
              <li>
                <em>-</em>
                <div>-</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
export default {
  name: 'DateGameBet',
  components: { DateFilterPt },
  data () {
    return {
    }
  }
}
</script>
<style src="@/styles/pt.css"></style>
<style scoped>
@media (max-width: 1000px) {
  .pagenamPT {flex-direction: column;align-items: flex-start;}
}
</style>
