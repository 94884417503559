import httpClient from '@/libs/http-client'

export function getUserListV2 (params) {
  const url = '/api/v2/user/lowerUserList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getUserOnlineListV2 (params) {
  const url = '/api/v2/user/loginList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getUserPlayListV2 (params) {
  const url = '/api/v2/user/detail/playGameList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getUserBetListV2 (params) {
  const url = '/api/v2/user/detail/betList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
