<template>
  <div id="PTpage" :class="getMobileTableClass" v-if="userData && gameCount && partnerLevelList">
    <header id="header">
      <div id="PTheadWrap">
        <a class="tab_toggle pc" @click="toggleTab" :class="{ 'tab-open': toggleMenuOpen }">
           <span></span>
           <span></span>
           <span></span>
         </a>
         <a class="tab_toggle m" @click="toggleTab" :class="{ 'tab-open': toggleMenuOpen }">
           <span></span>
           <span></span>
           <span></span>
         </a>
        <!--ul class="PTpageName userinfoM">
          <li><h2 class="PTmemName"><span>{{userData.memNick}}</span></h2></li>
          <li><button :class="'loginbtn'" :text="$t('front.common.logout')" @click="signOut"><img src="@/assets/img/icon_logout.svg"><span>로그아웃</span></button></li>
        </ul-->
        <div class="topInfo-wrap">
          <ul class="coninpointPT" v-if="userData">
            <li class="coinpoint">
              <div class="topBtn">
                <a @click="goPageByName('partnerCashList')"><em>보유머니 변동내역</em></a>
                <a @click="goPageByName('charge')"><em>충전신청</em></a>
                <a @click="goPageByName('exchange')"><em>환전신청</em></a>
                <a @click="goPageByName('point')"><em>롤링금 전환</em></a>
                <a @click="goPageByName('qna')"><em>1:1 문의 작성</em></a>
              </div>
              <!--div>
                <a @click="goPageByName('qna')"><em>1:1 문의 작성({{unReadQnaCount}})</em></a>
                <a @click="goPageByName('msg')"><em>쪽지({{unReadMessageCount}})</em></a>
              </div>
              <ul>
                <li>
                  <a>
                    <img src="@/assets/img/hcoin.svg" /><em>보유 머니</em><span class="cashbox ml12">{{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}</span>
                  </a>
                </li>
                <li>
                  <a style="cursor: pointer" @click="goPageByName('point')">
                    <img src="@/assets/img/hpoint.svg" /><em>보유 포인트</em><span class="cashbox">{{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}} P</span>
                  </a>
                </li>
              </ul-->
            </li>
          </ul>
          <div class="topInfo">
            <div>
              <em><img src="@/assets/img/icon_user.svg" /><i>회원</i></em>
              <span>{{thousand(mainTopInfo.userCnt || 0)}}</span>
            </div>
            <div>
              <em><img src="@/assets/img/icon_money.svg" /><i>보유머니</i></em>
              <span>{{thousand(mainTopInfo.totalCashAmt || 0)}}</span>
            </div>
            <div>
              <em><img src="@/assets/img/icon_point.svg" /><i>보유포인트</i></em>
              <span>{{thousand(mainTopInfo.totalPointAmt || 0)}}</span>
            </div>
            <!-- <div>
              <em>카지노롤링</em>
              <span>{{mainTopInfo.casinoRate}}%</span>
            </div>
            <div>
              <em>슬롯롤링</em>
              <span>{{mainTopInfo.slotRate}}%</span>
            </div> -->
          </div>
        </div>
      </div>
    </header>
    <div id="PTbodyWrap">
      <div class="PTtabWrap" :class="{ 'tab-open': toggleMenuOpen }">
        <ul class="PTpageName userinfoPC zindex101">
          <li>
             <a class="tab_toggle m" @click="toggleTab" :class="{ 'tab-open': toggleMenuOpen }">
               <span></span>
               <span></span>
               <span></span>
             </a>
             <h2 class="PTmemName"><span>{{userData.memNick}}</span></h2>
          </li>
          <li class="logoutwrap">
            <button :class="'loginbtn edit'" @click="goPageByName('infoEdit')"></button>
            <button :class="'loginbtn logout'" @click="signOut">{{ $t('front.common.logout') }}</button>
          </li>
        </ul>
        <div class="PTtabTop zindex101" :class="{ 'menu-open': isMenuOpen }">
          <table>
            <thead>
              <tr>
                <th colspan="2"><a @click="onChangeTab('partnerMain')">메인</a></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>접속계정</td>
                <td>{{userData.memNick}}</td>
              </tr>
            </tbody>
          </table>
          <div class="btnwrap">
            <h4 :class="{ 'menu_on' : menu_D }">바로가기<img src="@/assets/img/menu_arrow.svg" @click="menuOpen_D()"></h4>
            <div class="moneyBtn" :class="{ 'menu_on' : menu_D }">
              <div class="flex">
                <!-- <a class="createBtnPT grn flex_c" @click="onChangePartnerAddPopup('makeTab1')" v-if="userData.partnerLevel !== 'NORMAL'" :class="{ 'menu-open': isMenuOpen }"><img src="@/assets/img/go_partner.svg">파트너 생성</a> -->
                <a class="createBtnPT grn flex_c" @click="onChangePartnerAddPopup('makeTab2')" v-if="userData.partnerLevel !== 'NORMAL'" :class="{ 'menu-open': isMenuOpen }"><!--img src="@/assets/img/go_user.svg"-->직하부 회원 생성</a>
              </div>
              <!--a class="createBtnPT yel" @click="onChangeTab('partnerRate')">요율설정</a-->
            </div>
          </div>
        </div>
        <h4 class="menuTxt">메뉴</h4>
        <ul style="display:flex; cursor:pointer;" :class="{ 'menu-open': isMenuOpen }" class="zindex101">
          <!-- <li @click="onChangeTab('partnerMain')" :class="{'current': currentTab === 'partnerMain'}">
           <div>
             <span class=" PTtabimginout PTtabimg"></span>
             <h3>메인</h3>
           </div>
          </li> -->
          <li class="closeMenu" v-if="!isPartnerMember" data-tab="tab-2" @click="menuToggle('pList')" :class="{'open': pList || currentTab === 'partnerList' || currentTab.indexOf('partnerListLevel') > -1, 'disabled': userData.partnerLevel === 'PTN_6'}">
            <div>
              <span class=" PTtabimgsub PTtabimg"></span>
              <h3>파트너목록</h3>
              <div class="openMenu" >
                <a @click="onChangeTab('partnerList')" :class="{'on': currentTab === 'partnerList'}">전체 파트너 목록</a>

                <template v-for="pt in partnerLevelList" :key="pt.code">
                  <a v-if="pt.code !== 'NORMAL'" @click="onChangeTab('partnerListLevel' + pt.code)" :class="{'on': currentTab === 'partnerListLevel' + pt.code}" :data-key="pt.code">{{pt.codeName}} 목록</a>
                </template>
              </div>
            </div>
          </li>
          <li class="closeMenu" @click="menuToggle('uList')" :class="{'open': uList || currentTab === 'partnerUserList' || currentTab === 'onlinelist' }">
            <div>
              <span class=" PTtabimgsub PTtabimg"></span>
              <h3>회원관리</h3>
              <div class="openMenu" >
                <a @click="onChangeTab('partnerUserList')" :class="{'on': currentTab === 'partnerUserList'}">회원목록</a>
                <a @click="onChangeTab('onlinelist')" :class="{'on': currentTab === 'onlinelist'}">접속자 목록</a>
              </div>
            </div>
          </li>
          <li class="closeMenu" @click="menuToggle('bList')" :class="{'open': bList || currentTab === 'bettingslot' || currentTab === 'bettingcasino' || currentTab === 'bettingtotal' }">
            <div>
              <span class=" PTtabimgsub PTtabimg"></span>
              <h3>베팅 및 롤링현황</h3>
              <div class="openMenu">
                <a @click="onChangeTab('bettingtotal')" :class="{'on': currentTab === 'bettingtotal'}">베팅 현황(전체)</a>
                <a @click="onChangeTab('bettingcasino')" :class="{'on': currentTab === 'bettingcasino'}">베팅 현황(카지노)</a>
                <a @click="onChangeTab('bettingslot')" :class="{'on': currentTab === 'bettingslot'}">베팅 현황(슬롯)</a>
              </div>
            </div>
          </li>
          <li class="closeMenu" @click="menuToggle('gList')" :class="{'open': gList || currentTab === 'statDateBet' || currentTab === 'statDateGames' || currentTab === 'statDateUsers' || currentTab === 'statDateAgent' }">
            <div>
              <span class=" PTtabimgsub PTtabimg"></span>
              <h3>게임기록 통계</h3>
              <div class="openMenu">
                <a @click="onChangeTab('statDateBet')" :class="{'on': currentTab === 'statDateBet'}">일자별 베팅현황</a>
                <!--a @click="onChangeTab('statDateGames')" :class="{'on': currentTab === 'statDateGames'}">일자별 게임별</a-->
                <a @click="onChangeTab('statDateUsers')" :class="{'on': currentTab === 'statDateUsers'}">일자별 사용자별</a>
                <a @click="onChangeTab('statDateAgent')" :class="{'on': currentTab === 'statDateAgent'}">일자별 파트너별</a>
                <!--a @click="onChangeTab('statAllGame')" :class="{'on': currentTab === 'statAllGame'}">전체 게임별</a-->
<!--                <a @click="onChangeTab('statAllUser')" :class="{'on': currentTab === 'statAllUser'}">전체 사용자별</a>-->
              </div>
            </div>
          </li>
          <!--li @click="onChangeTab('partnerCashList')" :class="{'currentt': currentTab === 'partnerCashList', 'current': currentTab === 'partnerMoneyMoveList'}"-->
          <li @click="onChangeTab('cashListMy')" :class="{'currentt': currentTab === 'cashListMy'}">
           <div>
             <span class=" PTtabimginout PTtabimg"></span>
             <h3>보유머니 변동내역</h3>
           </div>
          </li>
          <!--li class="" data-tab="tab-5" @click="onChangeTab('partnerCashList')" :class="{'current': currentTab === 'partnerCashList'}">
           <div>
             <span class=" PTtabimginout PTtabimg"></span>
             <h3>입/출금내역</h3>
           </div>
          </!--li>
          <li class="" data-tab="tab-3" @click="onChangeTab('partnerMemberList')" :class="{'current': currentTab === 'partnerMemberList'}">
            <div>
              <span class=" PTtabimguser PTtabimg"></span>
              <h3>내 <br>소속회원목록</h3>
            </div>
          </li>
           <li class="" data-tab="tab-10" @click="onChangeTab('onlineMemberList')" :class="{'current': currentTab === 'onlineMemberList'}">
            <div>
              <span class=" PTtabimgon PTtabimg"></span>
              <h3>접속중인회원</h3>
            </div>
          </li>
          <li class="" v-if="!isPartnerMember" data-tab="tab-2" @click="onChangeTab('partnerList2')" :class="{'current': currentTab === 'partnerList2' , 'disabled': userData.partnerLevel === 'PTN_5'}">
            <div>
              <span class=" PTtabimgsub PTtabimg"></span>
              <h3>파트너목록 ||</h3>
            </div>
          </li>
           <li class="" data-tab="tab-6" @click="onChangeTab('partnerBettingList')" :class="{'current': currentTab === 'partnerBettingList'}">
            <div>
              <span class=" PTtabimgbet PTtabimg"></span>
              <h3>베팅내역</h3>
            </div>
          </li>
           <li class="" data-tab="tab-13" @click="onChangeTab('partnerBettingListSport')" :class="{'current': currentTab === 'partnerBettingListSport'}" v-if="gameCount.sports">
            <div>
              <span class=" PTtabimgsport PTtabimg"></span>
              <h3>베팅내역(스포츠)</h3>
            </div>
          </li>
          <li class="" v-if="!isPartnerMember" data-tab="tab-1" @click="onChangeTab('partnerCalculationList')" :class="{'current': currentTab === 'partnerCalculationList'}">
            <div>
              <span class=" PTtabimgptcal PTtabimg"></span>
              <h3>정산내역</h3>
            </div>
          </li>
          <li class="" data-tab="tab-4" @click="onChangeTab('partnerCalculationUserList')" :class="{'current': currentTab === 'partnerCalculationUserList'}">
            <div>
              <span class="userCal PTtabimg"></span>
              <h3>정산내역Ⅱ</h3>
            </div>
          </li>
          <li class="" v-if="!isPartnerMember" data-tab="tab-7" @click="onChangeTab('partnerMoneyMoveList')" :class="{'current': currentTab === 'partnerMoneyMoveList'}">
           <div>
             <span class=" PTtabimgmoney PTtabimg"></span>
             <h3>머니 지급 / 회수 내역</h3>
           </div>
          </li>
          <li class="" data-tab="tab-12" @click="onChangeTab('subpartnerPointList')" :class="{'current': currentTab === 'subpartnerPointList'}">
           <div>
             <span class=" PTtabimgusercal PTtabimg"></span>
             <h3>파트너 포인트 적립내역</h3>
           </div>
          </li>
          <li class="" v-if="!isPartnerMember" data-tab="tab-8" @click="onChangeTab('subMemberList')" :class="{'current': currentTab === 'subMemberList'}">
           <div>
             <span class="PTtabimgall PTtabimg"></span>
             <h3>소속 <br>전체 회원 목록</h3>
           </div>
          </li>
          <li class="" v-if="!isPartnerMember" @click="onChangeTab('partnerRate')" :class="{'current': currentTab === 'partnerRate' , 'disabled': userData.partnerLevel === 'PTN_5'}">
           <div>
             <span class=" PTtabimgsub PTtabimg"></span>
             <h3>요율설정</h3>
           </div>
          </li-->
        </ul>
        <!--div class="mobilemenuBtn" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div-->
      </div>
      <div class="PTboardwrap">
        <div class="">
          <!-- <div class="currentPT">
            <ul class="strheadPT">
              <li><h3 class="pagename2">현황</h3></li>
              <li class="ptUser"><span>{{$t('front.stributor.bottomPT')}}</span><span class="strnum" >{{summaryNew ? thousand(summaryNew.partnerCnt) : 0 }}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.bottomU')}}</span><span class="strnum" >{{summaryNew ? thousand(summaryNew.botMemCnt) : 0}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.inUsers')}}</span><span class="strnum" >{{summaryNew ? thousand(summaryNew.totalMemCnt) : 0}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.todayJoin')}}</span><span class="strnum" >{{summaryNew ? thousand(summaryNew.todayJoincnt) : 0}}</span></li>
            </ul>
          </div> -->
          <router-view :key="$route.fullPath" ></router-view>
          <!------------------------------------------------------------------------------->
          <div v-if="make" class="moveWrap makeWrap">
            <div class="makeWraphead">
              <ul class="groupTab">
                <!--li @click="currentTab2 = 'makeTab1'" :class="{'current': currentTab2 === 'makeTab1'}" v-if="newPartner.partnerLevel !== 'PTN_5' && userData.partnerLevel !== 'PTN_5'">파트너</li-->
                <li @click="currentTab2 = 'makeTab2'" :class="{'current': currentTab2 === 'makeTab2'}">회원</li>
                <li @click="currentTab2 = 'makeTab4'" :class="{'current': currentTab2 === 'makeTab4'}">그룹 회원</li>
              </ul>
              <a class="close" @click="onChangePartnerAddPopup"><img src="@/assets/img/icon_cancel.png" /></a>
              <div v-if="currentTab2 == 'makeTab1'">
                 <h4 v-if="makeTab == 'taba'">
                    파트너 생성<span>생성 파트너 등급 :
                    <template v-if="newPartnerLevel.codeName === '총본사'">
                      대본
                    </template>
                    <template v-else-if="newPartnerLevel.codeName === '대본사'">
                      본사
                    </template>
                    <template v-else-if="newPartnerLevel.codeName === '부본사'">
                      부본
                    </template>
                    <template v-else>
                      {{newPartnerLevel.codeName}}
                    </template>
                    </span>
                 </h4>
                 <h4 v-if="makeTab == 'tabb'">게임별 요율설정</h4>
                 <!--div class="stepWrap">
                   <span class="step on"><em></em>파트너생성</span>
                   <span class="stepLine" :class="{'on' : makeTab == 'tabb'}"></span>
                   <span class="step" :class="{'on' : makeTab == 'tabb'}"><em></em>요율설정</span>
                 </div-->
              </div>
              <h4 v-if="currentTab2 == 'makeTab2'">
                 회원 생성<span>추천인 아이디 : {{newPartner.recommenderId}}</span>
              </h4>
              <h4 v-if="currentTab2 == 'makeTab3'">요율설정</h4>
              <h4 v-if="currentTab2 == 'makeTab4'">그룹 회원 생성 <span>추천인 아이디 : {{newPartner.recommenderId}}</span></h4>
            </div>
            <div class="makeWrapbody" v-if="currentTab2 === 'makeTab1' && newPartner.partnerLevel !== 'PTN_5' && userData.partnerLevel !== 'PTN_5' ">
              <div v-if="makeTab == 'taba'">
                 <table class="part">
                   <colgroup>
                     <col width="40%"/>
                     <col width="60%"/>
                   </colgroup>
                   <tr>
                     <th>아이디</th>
                     <td><input class="in" name="newMemId" type="text" v-model="newPartner.memId" autocomplete="off" role="presentation"/>
                       <button class="checkBtn" @click="onCheckMemId(newPartner.memId, newPartner)">중복체크</button>
                    </td>
                   </tr>
                   <tr>
                     <th>비밀번호</th>
                     <td><input class="in" name="newMemPass" type="password" v-model="newPartner.memPass" autocomplete="off"/></td>
                   </tr>
                   <tr>
                     <th>닉네임</th>
                     <td>
                      <input class="in" type="text" v-model="newPartner.memNick"/>
                       <button class="checkBtn" @click="onCheckMemNick(newPartner.memNick, newPartner)">중복체크</button>
                    </td>
                   </tr>
                   <tr>
                     <th>출금 비밀번호</th>
                     <td><input class="in password" pattern="[0-9]*" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="4" type="text" v-model="newPartner.cashOutPass"/></td>
                   </tr>
                   <tr>
                     <th>생성파트너등급</th>
                     <td>
                        <template v-if="newPartnerLevel.codeName === '총본사'">
                         대본
                       </template>
                       <template v-else-if="newPartnerLevel.codeName === '대본사'">
                         본사
                       </template>
                       <template v-else-if="newPartnerLevel.codeName === '부본사'">
                         부본
                       </template>
                       <template v-else>
                         {{newPartnerLevel.codeName}}
                       </template>
                     </td>
                   </tr>
                   <tr>
                     <th>계좌정보</th>
                     <td class="accountInfo">
                       <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
                       <input class="in" type="text" v-model="newPartner.bankAcc"/>
                     </td>
                   </tr>
                   <tr>
                     <th>이름</th>
                     <td><input class="in" type="text" v-model="newPartner.memName"/></td>
                   </tr>
                   <tr>
                     <th>전화번호</th>
                     <td><input class="in" type="text" v-model="newPartner.memPhone"/></td>
                   </tr>
                 </table>
                 <div class="btnWrap">
                    <a class="btn" @click="onAddPartnerSubmit">{{$t('front.stributor.save')}}</a>
                    <!--a class="btn" @click="next('tabb')">다음</a-->
                 </div>
              </div>
              <!--div v-if="makeTab == 'tabb'" class="rate">
                 <div>
                   <p class="name">{{$t('front.stributor.roll')}}(%)</p>
                   <ul>
                     <li v-for="(item) in rateInfo" v-bind:key="item">
                       {{item.cateCodeName}}
                     </li>
                   </ul>
                   <ul>
                     <li v-for="(item) in rateInfo" v-bind:key="item">
                       <a class="rbnt">최대값 : {{item.pointRate}}</a>
                       <a class="rbnt">최소값 : 0</a>
                     </li>
                   </ul>
                   <ul>
                     <li v-for="(item) in newPartner.rateInfo.rateList" v-bind:key="item">
                       <input type="text" v-model="item.pointRate" >
                     </li>
                   </ul>
                 </div>
                 <div>
                   <p class="name">{{$t('front.stributor.lose')}}(%)</p>
                   <ul>
                     <li v-for="(item) in rateInfo" v-bind:key="item">
                       {{item.cateCodeName}}
                     </li>
                   </ul>
                   <ul>
                     <li v-for="(item) in rateInfo" v-bind:key="item">
                       <a class="rbnt">최대값 : {{item.loseRate}}</a>
                       <a class="rbnt">최소값 : 0</a>
                     </li>
                   </ul>
                   <ul>
                     <li v-for="(item) in newPartner.rateInfo.rateList" v-bind:key="item">
                       <input type="text" v-model="item.loseRate" >
                     </li>
                   </ul>
                 </div>
                 <div class="btnWrap">
                   <a class="btn" @click="next('taba')">이전</a>
                   <a class="btn" @click="onAddPartnerSubmit">{{$t('front.stributor.save')}}</a>
                 </div>
              </div-->
            </div>

            <div class="makeWrapbody" v-if="currentTab2 == 'makeTab2'">
              <table class="part">
                <colgroup>
                  <col width="40%"/>
                  <col width="60%"/>
                </colgroup>
                <tr>
                  <th>아이디</th>
                  <td><input class="in" type="text" autocomplete="off" v-model="newMember.memId"/>
                     <button class="checkBtn" @click="onCheckMemId(newMember.memId, newMember)">중복체크</button>
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td><input class="in" type="password" autocomplete="off" v-model="newMember.memPass"/></td>
                </tr>
                <tr>
                  <th>비밀번호 확인</th>
                  <td><input class="in" type="password" autocomplete="off" v-model="newMember.memPassCheck"/></td>
                </tr>
                <tr>
                  <th>전화번호</th>
                  <td><input class="in" type="text" v-model="newMember.memPhone"/></td>
                </tr>
                <tr>
                  <th>닉네임</th>
                  <td><input class="in" type="text" v-model="newMember.memNick"/>
                     <button class="checkBtn" @click="onCheckMemNick(newMember.memNick, newMember)">중복체크</button>
                  </td>
                </tr>
                <tr>
                  <th>추천인 아이디</th>
                  <td>
                     {{newPartner.recommenderId}}
                     <input class="in" type="hidden" v-model="newMember.recommenderId"/>
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td><input class="in" type="text" v-model="newMember.memName"/></td>
                </tr>
                <tr>
                  <th>출금 비밀번호</th>
                  <td><input class="in password" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" maxlength="4" pattern="[0-9]*" v-model="newMember.cashOutPass" inputmode="numeric"  /></td>
                </tr>
                <tr>
                  <th>계좌정보</th>
                  <td class="accountInfo">
                    <bank-list :className="'in'" @onChange="onChangeBankMember"></bank-list>
                    <input class="in w45w" type="text" v-model="newMember.bankacc" oninput="this.value = this.value.replace(/[^0-9.]/g, '')" />
                  </td>
                </tr>
              </table>
              <div class="btnWrap">
                <a class="btn" @click="newMemberSignup()">등록</a>
              </div>
            </div>

            <div class="makeWrapbody" v-if="currentTab2 == 'makeTab3'">
               <div>
                 <p class="name">내요율</p>
                 <div class="scroll mb40">
                    <table class="rolllose">
                      <tr>
                       <th></th>
                       <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                       <th v-if="gameCount['hc-casino']">{{$t('front.gnb.hotelcasino')}}</th>
                       <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                       <th v-if="gameCount['sports']">{{$t('front.gnb.sport')}}</th>
                       <template v-if="gameCount['mini-game'] || gameCount['mini game']">
                          <th>미니게임</th>
                       </template>
                      </tr>
                      <tr>
                       <th>{{$t('front.stributor.roll')}}(%)</th>
                       <td v-if="gameCount['casino']">{{myRate.casinoPR}}</td>
                       <td v-if="gameCount['hc-casino']">{{myRate.hcasinoPR}}</td>
                       <td v-if="gameCount['slot']">{{myRate.slotPR}}</td>
                       <td v-if="gameCount['sports']">{{myRate.sportPR}}</td>
                       <template v-if="gameCount['mini-game'] || gameCount['mini game']">
                          <td>{{myRate.miniPR}}</td>
                       </template>
                      </tr>
                      <tr>
                       <th>{{$t('front.stributor.lose')}}(%)</th>
                       <td v-if="gameCount['casino']">{{myRate.casinoLR}}</td>
                       <td v-if="gameCount['hc-casino']">{{myRate.hcasinoLR}}</td>
                       <td v-if="gameCount['slot']">{{myRate.slotLR}}</td>
                       <td v-if="gameCount['sports']">{{myRate.sportLR}}</td>
                       <template v-if="gameCount['mini-game'] || gameCount['mini game']">
                          <td>{{myRate.miniLR}}</td>
                       </template>
                      </tr>
                    </table>
                 </div>
             </div>
             <div>
                <p class="name">하부회원 요율조정</p>
                <div class="scroll">
                  <table class="rolllose2">
                    <tr>
                      <th>{{$t('front.common.memId')}}<em>({{$t('front.common.nickName')}})</em></th>
                      <th>분류</th>
                      <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                      <th v-if="gameCount['hc-casino']">{{$t('front.gnb.hotelcasino')}}</th>
                      <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                      <th v-if="gameCount['sports']">{{$t('front.gnb.sport')}}</th>
                      <template v-if="gameCount['mini-game'] || gameCount['mini game']">
                        <th>미니게임</th>
                        <th>하이로우88</th>
                      </template>
                    </tr>
                    <template v-if="myChildrenRate.length">
                      <template v-for="item in myChildrenRate" :key="item.memId">
                       <tr>
                          <td rowspan="2">{{item.memId}}<em>({{item.memNick}})</em></td>
                          <th>{{$t('front.stributor.roll')}}(%)</th>
                          <td v-if="gameCount['casino']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoPRError}" type="text" v-model="item.casinoPR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['hc-casino']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.hcasinoPRError}" type="text" v-model="item.hcasinoPR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['slot']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.slotPRError}" type="text" v-model="item.slotPR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['sports']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.sportPRError}" type="text" v-model="item.sportPR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['mini-game']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.miniPRError}" type="text" v-model="item.miniPR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['mini-game']">
                           <button class="detailSet" @click="toggleDetailSet(item)">세부설정</button>
                          </td>
                          <div class="detailSetWrap" v-if="isSelectedRow(item)">
                           <table>
                              <tbody>
                                <tr>
                                  <td rowspan="2" class="tableheadside">일반</td>
                                  <td>롤링</td>
                                  <td>
                                   <button class="btnm">최소<i>(1.05)</i></button>
                                   <input>
                                   <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>롤링</td>
                                  <td>
                                   <button class="btnm">최소<i>(1.05)</i></button>
                                   <input>
                                   <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                                <tr>
                                  <td rowspan="2" class="tableheadside">조합</td>
                                  <td>루징</td>
                                  <td>
                                   <button class="btnm">최소<i>(1.05)</i></button>
                                   <input>
                                   <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>루징</td>
                                  <td>
                                   <button class="btnm">최소<i>(1.05)</i></button>
                                   <input>
                                   <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                              </tbody>
                           </table>
                           <button class="detailSetSave">저장</button>
                           <span @click="toggleDetailSet" class="detailClose">×</span>
                          </div>
                       </tr>
                       <tr>
                          <th>{{$t('front.stributor.lose')}}(%)</th>
                          <td v-if="gameCount['casino']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoLRError}" type="text" v-model="item.casinoLR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['hc-casino']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.hcasinoPRError}" type="text" v-model="item.hcasinoLR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['slot']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.slotLRError}" type="text" v-model="item.slotLR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['sports']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.sportLRError}" type="text" v-model="item.sportLR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['mini-game']">
                             <button class="btnm">최소<i>(1.05)</i></button>
                             <input @change="onChangeChildrenRate(item)" :class="{'error': item.miniLRError}" type="text" v-model="item.miniLR" :disabled="userData.updId == 'royal77'">
                             <button class="btnx">최대<i>(5.05)</i></button>
                          </td>
                          <td v-if="gameCount['mini-game']">
                           <button class="detailSet" @click="toggleDetailSetlosing(item)">세부설정</button>
                          </td>
                          <div class="detailSetWrap detailLosing" v-if="isSelectedRowlosing(item)">
                           <table>
                              <tbody>
                                <tr>
                                  <td rowspan="2" class="tableheadside">일반</td>
                                  <td>롤링</td>
                                  <td>
                                    <button class="btnm">최소<i>(1.05)</i></button>
                                    <input>
                                    <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>롤링</td>
                                  <td>
                                    <button class="btnm">최소<i>(1.05)</i></button>
                                    <input>
                                    <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                                <tr>
                                  <td rowspan="2" class="tableheadside">조합</td>
                                  <td>루징</td>
                                  <td>
                                    <button class="btnm">최소<i>(1.05)</i></button>
                                    <input>
                                    <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>루징</td>
                                  <td>
                                    <button class="btnm">최소<i>(1.05)</i></button>
                                    <input>
                                    <button class="btnx">최대<i>(5.05)</i></button>
                                  </td>
                                </tr>
                              </tbody>
                           </table>
                           <button class="detailSetSave">저장</button>
                           <span @click="toggleDetailSetlosing" class="detailClose">×</span>
                          </div>
                       </tr>
                      </template>
                    </template>
                  </table>

                </div>
             </div>
             <p>하부회원의 최대 요율은 내 상위요율을 넘을 수 없습니다. 최소요율은 해당회원의 하부 최대 요율보다 낮을 수 없습니다.</p>
             <div class="btnWrap">
                <a class="btn" @click="onUpdateChildrenRate">{{$t('front.stributor.save')}}</a>
             </div>
            </div>

            <div class="makeWrapbody" v-if="currentTab2 == 'makeTab4'">
              <table class="part">
                <colgroup>
                  <col width="40%"/>
                  <col width="60%"/>
                </colgroup>
                <tr>
                  <th>아이디</th>
                  <td>
                     <input class="add" type="text" autocomplete="off" v-model="newMember.memId"/>
                     <input type="text" pattern="[0-9]*" name="" class="add2" v-model="groupMemIdStart"> ~
                     <input type="text" pattern="[0-9]*" name="" class="add2" v-model="groupMemIdEnd">
                     <button class="checkBtn" @click="groupMemIdCheck(groupMemIdStart, groupMemIdEnd)">중복체크</button>
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td><input class="in" type="password" autocomplete="off" v-model="newMember.memPass"/></td>
                </tr>
                <tr>
                  <th>비밀번호 확인</th>
                  <td><input class="in" type="password" autocomplete="off" v-model="newMember.memPassCheck"/></td>
                </tr>
                <tr>
                  <th>전화번호</th>
                  <td><input class="in" type="text" v-model="newMember.memPhone"/></td>
                </tr>
                <tr>
                  <th>닉네임</th>
                  <td>
                     <input class="add" type="text" v-model="newMember.memNick"/>
                    <input type="text" pattern="[0-9]*" name="" class="add2" v-model="groupMemIdStart"> ~
                    <input type="text" pattern="[0-9]*" name="" class="add2" v-model="groupMemIdEnd">
                     <button class="checkBtn" @click="groupMemNickCheck(groupMemIdStart, groupMemIdEnd)">중복체크</button>
                  </td>
                </tr>
                <tr>
                  <th>추천인 아이디</th>
                  <td>
                     {{newPartner.recommenderId}}
                     <input class="in" type="hidden" v-model="newMember.recommenderId"/>
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td>
                    <input class="add" type="text" v-model="newMember.memName"/>
                    <input type="text" pattern="[0-9]*" name="" class="add2" v-model="groupMemIdStart"> ~
                    <input type="text" pattern="[0-9]*" name="" class="add2" v-model="groupMemIdEnd">
                  </td>
                </tr>
                <tr>
                  <th>출금 비밀번호</th>
                  <td><input class="in password" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" maxlength="4" pattern="[0-9]*" v-model="newMember.cashOutPass" inputmode="numeric"  /></td>
                </tr>
                <tr>
                  <th>계좌정보</th>
                  <td class="accountInfo">
                    <bank-list :className="'in'" @onChange="onChangeBankMember"></bank-list>
                    <input class="in w45w" type="text" v-model="newMember.bankacc" oninput="this.value = this.value.replace(/[^0-9.]/g, '')" />
                  </td>
                </tr>
              </table>
              <div class="btnWrap">
                <a class="btn" @click="newMemberGroupSignup()">등록</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  partnerJoin, partnerLevels, partnerRates, retailUpdate, retailMemRate
} from '@/api/retail'
import {
  isValidOnlyNumber,
  isValidOnlyPhoneNumber,
  isValidPassword,
  isValidPasswordPartner,
  isValidUserId,
  thousand
} from '@/libs/utils'
import { mapState } from 'vuex'
import BankList from '@/components/ui/BankList.vue'
import { PARTNER_MOBILE_TABLE_SITE } from '@/libs/constants'
import { checkMemId, checkMemNick, signUp } from '@/api/member'
import store from '@/store'
import { getTopInfo } from '@/api/v2/top'
export default {
  name: 'Partner',
  components: { BankList },
  computed: {
    ...mapState([
      'siteIdInfo',
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode',
      'unReadQnaCount',
      'unReadMessageCount'
    ]),
    isPartnerMember () {
      return this.userData.loginType === 'P_NORMAL'
    },

    getMobileTableClass () {
      const siteId = process.env.VUE_APP_SITE_ID || ''
      let mobileTableClass = ''
      if (PARTNER_MOBILE_TABLE_SITE.includes(siteId)) {
        mobileTableClass = 'mobileTable'
      }
      return mobileTableClass
    }
  },
  data () {
    return {
      pList: false,
      uList: false,
      bList: false,
      gList: false,
      currentTab: this.$route.name,
      currentTab2: 'makeTab2',
      makeTab: 'taba',
      summary: {},
      rate: {},
      move: false,
      make: false,
      menu_D: true,
      myRate: {},
      myChildrenRate: [],
      selectedRow: null,
      selectedRowlosing: null,
      detailSet: false,
      newPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateInfo: {
          rateList: []
        },
        isCheckMemId: false,
        isCheckMemNick: false
      },
      defaultNewPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateInfo: {
          rateList: []
        },
        isCheckMemId: false,
        isCheckMemNick: false
      },
      newMember: {
        memId: '',
        memPass: '',
        memPassCheck: '',
        memPhone: '',
        memNick: '',
        memName: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0,
        isCheckMemId: false,
        isCheckMemNick: false
      },
      defaultMember: {
        memId: '',
        memPass: '',
        memPassCheck: '',
        memPhone: '',
        memNick: '',
        memName: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0,
        isCheckMemId: false,
        isCheckMemNick: false
      },
      groupMemIdStart: '',
      groupMemIdEnd: '',
      groupListCheck: false,
      groupListCheckNick: false,
      groupList: [],
      rateInfo: [],
      newPartnerLevel: {},
      partnerLevelList: [],
      partnerList: [],
      summaryNew: null,
      isMenuOpen: false,
      toggleMenuOpen: false,
      mainTopInfo: {
        userCnt: '',
        totalCashAmt: '',
        totalPointAmt: '',
        casinoRate: '',
        slotRate: ''
      }
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    await this.loadGameGroupCode()
    await this.loadCommonCode()
    this.loadTopInfo()
    // eslint-disable-next-line camelcase
    const menu_D = localStorage.getItem('menu_D')
    // eslint-disable-next-line camelcase
    this.menu_D = menu_D === null ? true : JSON.parse(menu_D)
    this.getPartnerLevels()
    this.newPartnerLevel = this.partnerLevelList[0]
    this.emitter.emit('Loading', false)

    this.emitter.on('ChangePay', () => {
      this.loadTopInfo()
    })
  },
  watch: {
    async userData (newVal, oldVal) {
      if (newVal) {
        await this.getPartnerRateForNewPartner()
      }
    },
    'newPartner.partnerLevel': function (newLevel) {
      if (this.newPartner.partnerLevel === 'PTN_5' || this.userData.partnerLevel !== 'PTN_5') {
        this.currentTab2 = 'makeTab2'
      } else {
        this.currentTab2 = 'makeTab1'
      }
    }
  },
  methods: {
    loadTopInfo () {
      getTopInfo({}).then(res => {
        console.log('getMainTopInfo : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          this.mainTopInfo = { ...result.data.mainTopInfo }
        }
      })
    },
    menuToggle (type) {
      if (!this[type]) {
        this[type] = false
      } else {
        this[type] = true
      }
      this[type] = !this[type]
    },
    menuTogglee (type) {
      if (!this[type]) {
        this[type] = false
      } else {
        this[type] = true
      }
      this[type] = !this[type]
    },
    loadGameGroupCode () {
      return store.dispatch('storeGameGroupCodes')
    },
    loadCommonCode () {
      return store.dispatch('storeCommonCode')
    },
    onCheckMemNick (memNick, obj) {
      if (memNick) {
        const params = {
          memNick
        }
        return checkMemNick(params).then(res => {
          console.log(res)
          const result = res.data
          if (result.resultCode === '0') {
            console.log('ok')
            obj.isCheckMemNick = true
            alert('닉네임이 등록이 가능합니다.')
            return true
          } else {
            obj.isCheckMemNick = false
            alert('중복된 닉네임이 존재합니다.')
            return false
          }
        })
      } else {
        obj.isCheckMemNick = false
        alert('닉네임을 입력해주세요')
        return false
      }
    },
    onCheckMemId (memId, obj) {
      if (memId) {
        const params = {
          memId
        }
        checkMemId(params).then(res => {
          console.log(res)
          const result = res.data
          if (result.resultCode === '0') {
            console.log('ok')
            obj.isCheckMemId = true
            alert('아이디 등록이 가능합니다.')
          } else {
            obj.isCheckMemId = false
            alert('중복된 아이디가 존재합니다.')
          }
        })
      } else {
        obj.isCheckMemId = false
        alert('아이디를 입력해주세요')
      }
    },
    menuOpen_D () {
      this.menu_D = !this.menu_D
      localStorage.setItem('menu_D', JSON.stringify(this.menu_D))
    },
    next (tab) {
      if (!this.newPartner.isCheckMemId) {
        alert('아이디 중복체크를 해주세요')
        return false
      }
      if (!this.newPartner.isCheckMemNick) {
        alert('닉네임 중복체크를 해주세요')
        return false
      }

      this.makeTab = tab
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    toggleTab () {
      this.toggleMenuOpen = !this.toggleMenuOpen
    },
    onChangeBankMember (value) {
      this.newMember.bank = value
    },
    isMemberSignupValidate () {
      const data = this.newMember
      for (const key of Object.keys(data)) {
        const value = data[key]
        if (key === 'memId') {
          if (value === '') {
            this.onAlert('warningart', 'front.member.emptyMemId')
            return false
          }

          if (!isValidUserId(value)) {
            this.onAlert('warningart', 'api.U101')
            return false
          }
        }

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }
        if (key === 'memPhone') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemTel')
            return false
          }

          if (!isValidOnlyPhoneNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidMemTel')
            return false
          }
        }
        if (key === 'memNick') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemNick')
            return false
          }

          if (value.length > 20 || value.length < 3) {
            this.onAlert('warningart', 'api.U105')
            return false
          }
        }
        if (key === 'recommenderId') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyRecommenderId')
            return false
          }
        }
        if (key === 'memName') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankAccountName')
            return false
          }
        }
        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }
        if (key === 'bank') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankName')
            return false
          }
        }
        if (key === 'bankacc') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankNumber')
            return false
          }

          if (!isValidOnlyNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidBankNumber')
            return false
          }
        }
        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }
      }
      return true
    },
    newMemberSignup () {
      if (!this.newMember.isCheckMemId) {
        alert('아이디 중복체크를 해주세요')
        return false
      }
      if (!this.newMember.isCheckMemNick) {
        alert('닉네임 중복체크를 해주세요')
        return false
      }
      this.emitter.emit('Loading', true)
      this.newMember.recommenderId = this.newPartner.recommenderId
      if (this.isMemberSignupValidate()) {
        this.newMember.memPhone = this.newMember.memPhone.toString()
        signUp(this.newMember).then(async response => {
          const result = response.data
          if (result.resultCode === '0') {
            await this.onCheck('front.member.completeSignup')
            this.makesub = false
            this.newMember = {
              ...this.defaultMember
            }
          } else {
            this.onAlert('warningart', 'api.' + result.resultCode)
          }
        }).catch(err => {
          console.error('singup err : ', err)
        })
      }
      this.emitter.emit('Loading', false)
    },
    async newMemberGroupSignup () {
      const newMem = { ...this.newMember }

      if (!this.groupListCheck) {
        alert('아이디 중복체크를 해주세요')
        return false
      }
      if (!this.groupListCheckNick) {
        alert('닉네임 중복체크를 해주세요')
        return false
      }
      this.emitter.emit('Loading', true)
      this.newMember.recommenderId = this.newPartner.recommenderId
      if (this.isMemberSignupValidate()) {
        this.newMember.memPhone = this.newMember.memPhone.toString()

        let successCnt = 0
        for (const extend of this.groupList) {
          this.newMember.memId = newMem.memId + extend
          this.newMember.memNick = newMem.memNick + extend
          console.log(this.newMember)
          await signUp(this.newMember).then(async response => {
            console.log(response)
            const result = response.data
            if (result.resultCode === '0') {
              // await this.onCheck('front.member.completeSignup')
              // this.makesub = false
              // this.newMember = {
              //   ...this.defaultMember
              // }
              successCnt += 1
            } else {
              // this.onAlert('warningart', 'api.' + result.resultCode)
              console.error(result.resultCode, result.resultMessage)
            }
          }).catch(err => {
            console.error('singup err : ', err)
          })
        }

        console.log(this.groupList.length, successCnt)

        this.emitter.emit('Loading', false)
        if (this.groupList.length === successCnt) {
          await this.onCheck('front.member.completeSignup')
          location.reload()
        } else {
          await this.onAlert('warningart', '확인 후 다시시도해주세요')
        }
      }
      this.emitter.emit('Loading', false)
    },
    onChangeTab (name, param) {
      console.log('onChangeTab : ', name, param)
      if (param && param.code) {
        console.log('onChangeTab param : ', param)
        this.currentTab = param.code
      } else {
        this.currentTab = name
      }
      this.goPageByName(name, param)
    },
    toggleDetailSet (item) {
      if (this.isSelectedRow(item)) {
        this.selectedRow = null
      } else {
        this.selectedRow = item
      }
    },
    isSelectedRow (item) {
      return this.selectedRow === item
    },
    toggleDetailSetlosing (item) {
      if (this.isSelectedRow(item)) {
        this.selectedRowlosing = null
      } else {
        this.selectedRowlosing = item
      }
    },
    isSelectedRowlosing (item) {
      return this.selectedRowlosing === item
    },
    onChangeChildrenRate (item) {
      const casinoPR = Number(item.casinoPR)
      if (isNaN(casinoPR)) {
        item.casinoPRError = true
      }
      if (casinoPR || casinoPR === 0) {
        const maxCasinoPR = Number(item.maxCasinoPR)
        const minCasinoPR = Number(item.minCasinoPR)
        if (maxCasinoPR < casinoPR || minCasinoPR > casinoPR) {
          item.casinoPRError = true
        } else {
          item.casinoPRError = false
        }
      }

      const hcasinoPR = Number(item.hcasinoPR)
      if (isNaN(hcasinoPR)) {
        item.hcasinoPRError = true
      }
      if (hcasinoPR || hcasinoPR === 0) {
        const maxHcasinoPR = Number(item.maxHcasinoPR)
        const minHcasinoPR = Number(item.minHcasinoPR)
        console.log(hcasinoPR, maxHcasinoPR, minHcasinoPR)
        if (maxHcasinoPR < hcasinoPR || minHcasinoPR > hcasinoPR) {
          item.hcasinoPRError = true
        } else {
          item.hcasinoPRError = false
        }
        console.log(item.hcasinoPRError)
      }

      const slotPR = Number(item.slotPR)
      if (isNaN(slotPR)) {
        item.slotPRError = true
      }
      if (slotPR || slotPR === 0) {
        const maxSlotPR = Number(item.maxSlotPR)
        const minSlotPR = Number(item.minSlotPR)
        if (maxSlotPR < slotPR || minSlotPR > slotPR) {
          item.slotPRError = true
        } else {
          item.slotPRError = false
        }
      }

      const miniPR = Number(item.miniPR)
      if (isNaN(miniPR)) {
        item.miniPRError = true
      }
      if (miniPR || miniPR === 0) {
        const maxMiniPR = Number(item.maxMiniPR)
        const minMiniPR = Number(item.minMiniPR)
        if (maxMiniPR < miniPR || minMiniPR > miniPR) {
          item.miniPRError = true
        } else {
          item.miniPRError = false
        }
      }

      const sportPR = Number(item.sportPR)
      if (isNaN(sportPR)) {
        item.sportPRError = true
      }
      if (sportPR || sportPR === 0) {
        const maxSportPR = Number(item.maxSportPR)
        const minSportPR = Number(item.minSportPR)
        if (maxSportPR < sportPR || minSportPR > sportPR) {
          item.sportPRError = true
        } else {
          item.sportPRError = false
        }
      }

      const casinoLR = Number(item.casinoLR)
      if (isNaN(casinoLR)) {
        item.casinoLRError = true
      }
      if (casinoLR || casinoLR === 0) {
        const maxCasinoLR = Number(item.maxCasinoLR)
        const minCasinoLR = Number(item.minCasinoLR)
        if (maxCasinoLR < casinoLR || minCasinoLR > casinoLR) {
          item.casinoLRError = true
        } else {
          item.casinoLRError = false
        }
      }

      const hcasinoLR = Number(item.hcasinoLR)
      if (isNaN(hcasinoLR)) {
        item.hcasinoLRError = true
      }
      if (hcasinoLR || hcasinoLR === 0) {
        const maxHcasinoLR = Number(item.maxHcasinoLR)
        const minHcasinoLR = Number(item.minHcasinoLR)
        if (maxHcasinoLR < hcasinoLR || minHcasinoLR > hcasinoLR) {
          item.hcasinoLRError = true
        } else {
          item.hcasinoLRError = false
        }
      }

      const slotLR = Number(item.slotLR)
      if (isNaN(slotLR)) {
        item.slotLRError = true
      }
      if (slotLR || slotLR === 0) {
        const maxSlotLR = Number(item.maxSlotLR)
        const minSlotLR = Number(item.minSlotLR)
        if (maxSlotLR < slotLR || minSlotLR > slotLR) {
          item.slotLRError = true
        } else {
          item.slotLRError = false
        }
      }

      const miniLR = Number(item.miniLR)
      if (isNaN(miniLR)) {
        item.miniLRError = true
      }
      if (miniLR || miniLR === 0) {
        const maxMiniLR = Number(item.maxMiniLR)
        const minMiniLR = Number(item.minMiniLR)
        if (maxMiniLR < miniLR || minMiniLR > miniLR) {
          item.miniLRError = true
        } else {
          item.miniLRError = false
        }
      }

      const sportLR = Number(item.sportLR)
      if (isNaN(sportLR)) {
        item.sportLRError = true
      }
      if (sportLR || sportLR === 0) {
        const maxSportLR = Number(item.maxSportLR)
        const minSportLR = Number(item.minSportLR)
        if (maxSportLR < sportLR || minSportLR > sportLR) {
          item.sportLRError = true
        } else {
          item.sportLRError = false
        }
      }
    },
    async onUpdateChildrenRate () {
      const rateList = this.myChildrenRate
      console.log(rateList)

      for (let i = 0, iLen = rateList.length; i < iLen; i++) {
        const item = rateList[i]
        if (item.casinoLRError ||
          item.casinoPRError ||
          item.hcasinoPRError ||
          item.hcasinoLRError ||
          item.slotLRError ||
          item.slotPRError ||
          item.miniLRError ||
          item.miniPRError ||
          item.sportLRError ||
          item.sportPRError
        ) {
          return
        }
      }

      const confirm = await this.onConfirm('front.common.confirmSave')
      if (confirm) {
        const param = {
          siteId: '',
          memId: '',
          rateList: rateList
        }

        this.emitter.emit('Loading', true)
        retailUpdate(param).then(res => {
          this.emitter.emit('Loading', false)
          const data = res.data
          if (data.resultCode === '0') {
            this.onCheck('front.recommender.complete')
            this.onLoadRate()
          }
        })
      }
    },
    onLoadRate () {
      retailMemRate({}).then(res => {
        const data = res.data
        console.log('retailMemRate : ', data)
        if (data.resultCode === '0') {
          this.myRate = data.data.myCategoryRate
          this.myChildrenRate = data.data.botCategoryRateList
        }
      })
    },
    thousand,
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        console.log('partnerLevels : ', res.data)
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangePartnerAddPopup (type) {
      this.make = !this.make
      this.currentTab2 = type
      if (this.make) {
        this.newPartner.recommenderId = this.userData.memId
        const list = this.rateInfo
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          this.newPartner.rateInfo.rateList.push({
            category: item.category,
            cateCode: item.cateCode,
            pointRate: item.pointRate,
            loseRate: item.loseRate
          })
        }
      }
      console.log(this.newPartner)
    },
    getPartnerRateForNewPartner () {
      console.log(this.siteIdInfo)
      const params = {
        siteId: this.siteIdInfo.siteId,
        memId: this.userData.memId
      }
      console.log('req요율 : ', params)
      return partnerRates(params).then(res => {
        const result = res.data
        console.log('res요율 : ', result)
        if (result.resultCode === '0') {
          this.rateInfo = result.data.rateInfo
        }
      })
    },
    onAddPartnerSubmit () {
      const reqData = { ...this.newPartner }
      if (!reqData.memId) {
        this.onAlert('warningart', 'front.member.emptyMemId')
        return false
      }

      if (!isValidUserId(reqData.memId)) {
        this.onAlert('warningart', 'api.U101')
        return false
      }

      if (!reqData.memPass) {
        this.onAlert('warningart', 'front.member.emptyMemPass')
        return false
      }
      if (!isValidPasswordPartner(reqData.memPass) || reqData.memPass.length > 20) {
        this.onAlert('warningart', 'api.U102')
        return false
      }

      if (!reqData.memNick) {
        this.onAlert('warningart', 'front.member.emptyMemNick')
        return false
      }
      if (!reqData.cashOutPass) {
        this.onAlert('warningart', 'front.member.emptyCashOutPass')
        return false
      }
      if (!reqData.bank) {
        this.onAlert('warningart', 'front.member.emptyBankName')
        return false
      }
      if (!reqData.bankAcc) {
        this.onAlert('warningart', 'front.member.emptyBankNumber')
        return false
      }
      if (!reqData.memName) {
        this.onAlert('warningart', 'front.member.emptyMemName')
        return false
      }
      if (!reqData.memPhone) {
        this.onAlert('warningart', 'front.member.emptyMemTel')
        return false
      }

      this.emitter.emit('Loading', true)

      return partnerJoin(reqData).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('하위파트너 생성신청이 완료되었습니다. 관리자에서 승인되면 가입처리가 완료됩니다.')
          this.onChangePartnerAddPopup()
          this.newPartner = { ...this.defaultNewPartner }
          this.emitter.emit('Loading', false)
        } else {
          this.emitter.emit('Loading', false)
          this.onAlert('warningart', result.resultMessage)
        }
      })
    },
    onChangeBank (value) {
      this.newPartner.bank = value
    },
    async memIdCheck (memId) {
      const reqData = {
        memId
      }
      const res = await checkMemId(reqData)
      if (res.resultCode === '0') {
        if (res.data.member.memId) {
          alert('중복된 회원입니다. 다시 입력해주세요.')
          this.joinData.memId = ''
          return false
        }
      } else {
        alert('사용가능합니다.')
        return true
      }
    },
    async memIdCheckForGroup (memId) {
      const reqData = {
        memId
      }
      const res = await checkMemId(reqData)
      console.log(res)
      if (res.data.resultCode === '0') {
        return true
      } else {
        return false
      }
    },
    async groupMemIdCheck (startValue, endValue) {
      this.groupList = []
      const start = Number(startValue)
      const end = Number(endValue)
      if (!this.newMember.memId) {
        alert('아이디를 입력해주세요')
        this.groupListCheck = false
        return false
      }

      // const isMemIdValid = await this.memIdCheck(this.newMember.memId)
      // if (!isMemIdValid) {
      //   this.groupListCheck = false
      //   return false
      // }

      this.groupListCheck = true

      this.groupListCheck = this.groupCheck(start, end)
      if (!this.groupListCheck) {
        return false
      }

      for (let i = start; i <= end; i++) {
        if (i < 10) {
          if (i === start) {
            this.groupMemIdStart = '0' + i.toString()
          }
          if (i === end) {
            this.groupMemIdEnd = '0' + i.toString()
          }
          this.groupList.push('0' + i.toString())
        } else {
          this.groupList.push(i.toString())
        }
      }

      for (const item of this.groupList) {
        console.log(this.newMember.memId + item)
        const isMemIdValid = await this.memIdCheckForGroup(this.newMember.memId + item)
        console.log(isMemIdValid)
        if (!isMemIdValid) {
          alert('중복된 회원입니다. 다시 입력해주세요.')
          this.groupListCheck = false
          return false
        }
      }

      console.log(this.groupList)
      if (this.groupListCheck) {
        alert('정상적으로 확인하였습니다.')
      }
    },
    async groupMemNickCheck (startValue, endValue) {
      const start = Number(startValue)
      const end = Number(endValue)
      if (!this.newMember.memNick) {
        alert('닉네임을 입력해주세요')
        this.groupListCheckNick = false
        return false
      }

      const isMemNickValid = await this.onCheckMemNick(this.newMember.memNick, this.newMember)
      console.log(isMemNickValid)
      if (!isMemNickValid) {
        this.groupListCheckNick = false
        return false
      }

      this.groupListCheckNick = true

      this.groupListCheckNick = this.groupCheck(start, end)

      for (let i = start; i <= end; i++) {
        if (i < 10) {
          if (i === start) {
            this.groupMemIdStart = '0' + i.toString()
          }
          if (i === end) {
            this.groupMemIdEnd = '0' + i.toString()
          }
          // this.groupList.push('0' + i.toString())
        } else {
          // this.groupList.push(i.toString())
        }
      }
      if (this.groupListCheckNick) {
        alert('정상적으로 확인하였습니다.')
      }
    },
    groupCheck (startValue, endValue) {
      const start = parseInt(startValue)
      const end = parseInt(endValue)
      console.log(start, end)

      if (isNaN(start) || isNaN(end)) {
        alert('숫자만 입력해주세요')
        return false
      }

      if (start >= end) {
        alert('뒷자리가 앞자리보다 커야 합니다.')
        return false
      }

      if ((end - start) > 9) {
        alert('최대 10명까지 가입이 가능합니다.')
        return false
      }
      return true
    }
  }
}
</script>
<style>
footer { display: none !important; }
.tab-content.current{display: inherit;}
.blc {color: #2F4DF2 !important;font-weight: bold;}
.rdc {color: #e1534e;font-weight: bold;}
.strTable.more {/*border: 2px solid #e5972d;*/width: 100%;}
.moreT img {margin-right: 6px;}
.strTable {width: 100%;text-align: center;vertical-align: middle;font-size: 12px; overflow: hidden;border-collapse: collapse;border-spacing: 0;box-sizing: border-box;}
.strTable.moreTable {border-left: 0;border-right: 0;}
.strTable th {height: 40px;background: #EEF0F5;color: #000000;vertical-align: middle;}
.strTable td {height: 35px;background: #FBFCFD;vertical-align: middle;border-bottom: solid 1px #E9EAEF;white-space: nowrap;color: #000000;overflow: hidden;text-overflow: ellipsis;position: relative;}
.strTable td:last-child {border-right: 0;}
.money {text-align: left;}
.money li {padding: 0 10px;height: 30px;display: flex;align-items: center;justify-content: space-between; letter-spacing: -0.6px;}
.money li:nth-child(4) {background: #fff; color: #F75D4C; background-color: #ffedee;
}
.moreTable .money li:nth-child(4) {background: #fff; color: #F75D4C;}
/* .moreBtn {box-sizing: border-box;display: flex;align-items: center;justify-content: center;color: #2F4DF2;position: absolute;top: 50%;left:5px;transform: translate(0, -50%);width: 18px;height: 18px;cursor: pointer;transition: all 0.7s ease;font-size: 22px;border:1px solid #2F4DF2;border-radius: 3px;}
.moreBtn img {height: 22px;transform: rotate(90deg);transition: all 0.7s ease;} */

.strTable td:first-child,
.strTable td:first-child .name {position: relative;}
/*.line .strTable td:first-child::before {
   content: '';
   width: 1px;
   height: 100%;
   background: #E9EAEF;
   position: absolute;
   left: 20px;
   top: 0;
}
.line .strTable .strTable td:first-child::before {left: 30px;}
.line .strTable .strTable .strTable td:first-child::before {left: 40px;}
.line .strTable .strTable .strTable .strTable td:first-child::before {left: 50px;}
.line .strTable .strTable .strTable .strTable .strTable td:first-child::before {left: 60px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable td:first-child::before {left: 70px;}
.line .strTable td:first-child .name::before {
  content: '';
  width: 10px;
  height: 1px;
  background: #E9EAEF;
  position: absolute;
  left: 20px;
  top: 50%;
}*/
.line .strTable .strTable td:first-child .name::before {left: 30px;}
.line .strTable .strTable .strTable td:first-child .name::before {left: 40px;}
.line .strTable .strTable .strTable .strTable td:first-child .name::before {left: 50px;}
.line .strTable .strTable .strTable .strTable .strTable td:first-child .name::before {left: 60px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable td:first-child .name::before {left: 70px;}

.line .strTable li:first-child .name {padding-left: 30px;}
.line .strTable .strTable li:first-child .name {padding-left: 40px;}
.line .strTable .strTable .strTable li:first-child .name {padding-left: 50px;}
.line .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 60px;}
.line .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 70px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 80px;}

.line .strTable .strTable .moreBtn {margin-left: 10px;}
.line .strTable .strTable .strTable .moreBtn {margin-left: 20px;}
.line .strTable .strTable .strTable .strTable .moreBtn {margin-left: 30px;}
.line .strTable .strTable .strTable .strTable .strTable .moreBtn {margin-left: 40px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable .moreBtn {margin-left: 50px;}

#PTtab-4 .line .strTable li:first-child .name {padding-left: 20px;}

.name {padding-left: 10px;text-align: left;display: flex; align-items: center; gap: 5px;}
.name span {display: inline-block;font-size: 12px;color: #000000;margin-bottom: 5px;}
.name em {display: block;color: #000000;margin-top: 5px;}
.lRoll span:first-child {margin-bottom: 6px;}
.lRoll span {display: block;text-align: left;padding-left: 5px;}
.lRoll input {width: 45px;margin: 0 3px;}
.userBetBtn {border: 1px solid #2F4DF2;background: #6980FC;border-radius: 5px;font-size: 12px;padding: 5px 20px;display: inline-block;cursor: pointer;position: absolute;top: 5px;color:#fff;}
.userBetWrap {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;padding: 0 0 70px;border: 2px solid #777;z-index: 1;width: 100%;max-width: 1200px; min-height: 500px; max-height: 820px;}
.userBetWrap .close {position: absolute;right: 20px;top: 20px;cursor: pointer;display: inline-block;width: 30px; height: 30px; background: url(../../../assets/img/icon_cancelB.svg) no-repeat; background-size: contain;}
.userBetWrap .close img {height: 25px;}
.userBetWrap h4 {font-size: 20px;font-weight: bold;margin: 40px 25px 25px;position: relative;color: #000;text-align: left;}
.userBetWrap .tab-content {max-width: 1600px;width: calc(100% - 25px);margin: 0 0 30px 25px;}
.userBetWrap > div {white-space:nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align:center;}
.userBetWrap > div::-webkit-scrollbar {display: none;}
.userBetWrap .board {width: 1150px;}
.gameName {background: #fff;border-radius: 5px;border: 1px solid #60452a;color: #000;margin: 0 25px 28px;height: 30px;padding: 0 10px;display: flex;justify-content: left;}

.mybetwrap .tabs{display: flex;align-items: center;justify-content: center;gap: 7px;margin: 0 25px 28px;}
.mybetwrap .tabs li{text-align: center;width: 100%;color: #60452a;cursor: pointer;font-size: 14px;border-radius: 10px;border: solid 1px #c5ad75;background-color: #fff;padding: 9px 0;}

.mybetwrap .board>.boardw.first:first-child {background: linear-gradient( to bottom, #c5ad75, #d3aa4b);color: #fff;border: 0;border-radius: 10px 10px 0 0;}
.mybetwrap .boardw:last-child {border-bottom: 1px dotted #60452a;}
.mybetwrap .boardw {width:100%;border-bottom: 1px dotted #60452a;background: #fff;display: flex;text-align: center;justify-content: space-around;align-items: center;height:45px;line-height: 1.5em;font-size: 14px;cursor: pointer;color: #000;box-sizing: border-box;}
.mybetwrap .boardw li{width:100%;display: flex;align-items: center;justify-content: center;line-height: 1.2em;overflow: hidden;}
.mybetwrap .boardw li a {color: #000;}

/*datesearch*/
.datesearchPT .datesearch {background:none !important;}
.datesearchPT .datesearch span {color:#000 !important;}
/* 등급 매장일 때 하부파트너목록 버튼 비활성화 */
.disabled { display: none; }

@media (max-width: 1000px) {
  .line {white-space: nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align: center;}
  .line .strTable li:first-child .name {padding-left: 10px;}
  .line .strTable .strTable li:first-child .name {padding-left: 10px;}
  .line .strTable .strTable .strTable li:first-child .name {padding-left: 10px;}
  .line .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 10px;}
  .line .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 10px;}
  .line .strTable .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 10px;}
  #PTtab-4 .line .strTable li:first-child .name {padding-left: 10px;}
}
@media screen and (max-width: 799px) {
  /* .line .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}*/
}
</style>

<style scoped>
.pb30 {padding-bottom:30px;}
.w100w {margin: 0 20px auto;min-width: 1314px;}
.nonebtn {background: none !important;border: 0 !important;width: auto !important;}
.rollname {font-size: 16px;color: #000;margin-bottom: 18px;}
.close {position: absolute;right: 15px;top: 15px;}
.board {box-shadow: 0 0px 6px 0 rgb(0 0 0 / 20%);border-radius: 0 0 10px 10px;}
.boardstrin .rollname {margin-top: 30px;}
.board.even .boardstr {background: #f8f9fb;}
.datesearchPT {display: flex;justify-content: space-between;align-items: center;}
.datesearchPT .btn {background: #fc7220;font-size: 14px;width: 93px;height: 28px;color: #fff;line-height: 28px;text-align: center;border-radius: 5px;margin-bottom: 18px;}
.searchPT {display: flex; gap:10px;}
.PTsch .searchPT h5{line-height: 30px;font-size: 14px;padding-left:120px}
.saveBtnPT {background: #2F4DF2;font-size: 14px;padding: 0 10px;height: 28px;color: #fff;line-height: 28px;text-align: center;border-radius: 5px;display: inline-block;}
.moveWrap {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;z-index: 5;font-family: 'NanumGothic';box-shadow: 0 0 10px 1px #ddd;}
.moveWrap .close {position: absolute;right: 50px;top: 18px;}
.moveWrap .close img {height: 28px;}
.moveWraphead { padding: 20px 50px; background:#575C68; color: #fff; font-size: 18px; }
.moveWrapbody { padding: 0 50px 20px; }
.moveWrap h4 {font-size: 16px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #575C68;}
.moveWrap table {width: 800px;text-align: center;}
.moveWrap th {height: 40px;background: #828793; border: solid 1px #DDDEE2;color: #fff;vertical-align: middle;}
.moveWrap table tr:nth-child(2) th { background:#EEF0F5; color: #575C68; }
.moveWrap .scroll table tr:nth-child(2) th { background:#828793; color: #fff;}
.moveWrap td {height: 40px;background: #FBFCFD;vertical-align: middle;border: solid 1px #EEF0F5;white-space: nowrap;color: #575C68;overflow: hidden;text-overflow: ellipsis;position: relative;}
.moveWrap input {width: 50px;border: 1px solid #0000F0;background: #fff;border-radius: 3px;color: #575C68;text-align: center;}
.moveWrap input.in {width: 60%;height: 28px;}
.moveWrap input.in.password {-webkit-text-security: disc;-moz-webkit-text-security: disc;-moz-text-security: disc;}
.moveWrap input.add {width: 50%;height: 28px;}
.moveWrap input.add2 {width: 17%;height: 25px;border: 1px solid #fff;border-radius: 4px;padding-left: 5px;box-sizing: border-box;}
.moveWrap .accountInfo input.in { width: 54%; }
.moveWrap select.in {width: 80px;height: 28px;border: 1px solid #60452a;background: #fff;color: #575C68;margin-right: 5px;}
.moveWrap p {margin-top: 25px;color: #2A40B9;font-size: 12px;text-align: right;}
.moveWrap a.btn {margin-top: 25px;background: #2F4DF2;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
.rbnt {display: block;margin-bottom: 10px;}
.rbnt:first-child {margin-top: 10px;}
.error {border-color: #ff3f45 !important;}
.scroll {max-height: 400px;overflow-y: auto;}
.total td {background: #141414;}
.moreBtn.b {position: relative;border: solid 1px #fff;width: 50px;margin: 0 auto;}
@media screen and (max-width: 799px) {
  .moreBtn {box-sizing: border-box;display: flex;align-items: center;justify-content: center;color: #2F4DF2;position: absolute;top: 0;left:0;width: 18px;height: 18px;cursor: pointer;transition: all 0.7s ease;font-size: 22px;border:1px solid #2F4DF2;border-radius: 3px;}
  .line .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
}

.makeWraphead h4 {border-top: 2px solid #fff;margin-top: 0 !important;padding-top: 20px;}
.groupTab {display: flex;gap: 5px;margin: 30px auto 0;width: 280px;color: #9f9999;}
.groupTab li {width: 100%;height: 33px;background: #353537;border: 1px solid #fff;border-bottom: 0;border-radius: 6px 6px 0 0;display: flex;align-items: center;justify-content: center;cursor: pointer;}
.groupTab li.current {background: #fff;color: #000;height: 33px;}

.moveWrap2 {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;z-index: 5;font-family: 'NanumGothic';box-shadow: 0 0 10px 1px #ddd;}
.moveWrap2 .close {position: absolute;right: 50px;top: 18px; cursor: pointer;}
.moveWrap2 .close img {height: 35px;}
.moveWraphead { padding: 20px 50px; background:#575C68; color: #fff; font-size: 18px; }
.moveWrapbody { padding: 0 50px 20px; }
.moveWrap2 h4 {font-size: 16px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #575C68;}
.moveWrap2 table {width: 800px;text-align: center;}
.moveWrap2 th {height: 40px;background: #828793; border: solid 1px #DDDEE2;color: #fff;vertical-align: middle;}
.moveWrap2 table tr:nth-child(2) th { background:#EEF0F5; color: #575C68; }
.moveWrap2 .scroll table tr:nth-child(2) th { background:#828793; color: #fff;}
.moveWrap2 td {height: 40px;background: #FBFCFD;vertical-align: middle;border: solid 1px #EEF0F5;white-space: nowrap;color: #575C68;overflow: hidden;text-overflow: ellipsis;position: relative;}
.moveWrap2 input {width: 50px;border: 1px solid #0000F0;background: #fff;border-radius: 3px;color: #575C68;text-align: center;}
.moveWrap2 input.in {width: 90%;height: 28px;}
.moveWrap2 input.in.password {-webkit-text-security: disc;-moz-webkit-text-security: disc;-moz-text-security: disc;}
.moveWrap2 .accountInfo input.in { width: 54%; }
.moveWrap2 .accountInfo input.in.w45w {width: 45%;}
.moveWrap2 select.in {width: 80px;height: 28px;border: 1px solid #60452a;background: #fff;color: #575C68;margin-right: 5px;}
.moveWrap2 p {margin-top: 25px;color: #2A40B9;font-size: 12px;text-align: right;}
.moveWrap2 a.btn {margin-top: 25px;background: #2F4DF2;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
.tabmenu_make > ul {display: flex; gap: 10px; }
.tabmenu_make > ul li {border: 1px solid; border-radius: 10px; padding: 8px 15px; cursor: pointer; }
.tabmenu_make > ul li.current {background: #fff;color: #575C68;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped src="@/styles/striNew.css"></style>
<style scoped>
.open h3 {height: 50px;display: flex;align-items: center;}
.closeMenu.open h3::after {content: '▲';}
.closeMenu h3::after {content: '▼';position: absolute;right: 20px;}
.open > div {height: auto !important;flex-wrap: wrap;}
.open .openMenu {display: flex;}
.openMenu {display: none;flex-direction: column;width: 100%;gap: 22px;padding: 0 0 11px 15px;font-weight: normal;}

.openMenu a:hover, .openMenu .on {color: #f67b0c;}

li:nth-child(1) .openMenu .on, li:nth-child(1) .openMenu a:hover {color: #2ea4c3;}
li:nth-child(2) .openMenu .on, li:nth-child(2) .openMenu a:hover {color: #dfbb4c;}
li:nth-child(3) .openMenu .on, li:nth-child(3) .openMenu a:hover {color: #db3e44;}
li:nth-child(4) .openMenu .on, li:nth-child(4) .openMenu a:hover {color: #bf77ce;}
li:nth-child(5) .openMenu .on, li:nth-child(5) .openMenu a:hover {color: #78c2b0;}

.topBtn {gap: 10px !important;}
.topBtn a {display: flex;align-items: center;justify-content: center;padding: 0 15px;height: 34px;box-sizing: border-box;font-size: 14px;border-radius: 3px;box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.35);background-image: linear-gradient(to bottom, #e6e6e6 100%, #909090 0%);text-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);}
.topBtn a:hover {background-image: linear-gradient(104deg, #f1582c, #e20e85);color: #fff;}
.topInfo-wrap {display: flex;flex-direction: row-reverse;gap: 10px;}
.topInfo {display: flex;align-items: center;justify-content: end;gap: 10px;}
.topInfo > div {display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 5px;width: 135px;height: 45px;border-radius: 3px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background-color: #363636;font-size: 12px;color: #fff;}
.topInfo em {display: flex;align-items: center;gap: 4px;}
.topInfo img {width: 16px;}
.topInfo span {font-size: 14px;}
.logoutwrap {align-items: center !important;}
.loginbtn {cursor: pointer;color: #fff;font-size: 12px;border: 0;background: none; padding: 0;width: 18px;}
@media (min-width: 1001px) {
  .loginbtn.edit img {display: block;}
}
.loginbtn.edit {
  background: url('../../../assets/img/icon_set.svg') no-repeat;
  width: 20px;height: 20px;
  background-size: cover;
}
.loginbtn.logout {
  /*background: url('../../../assets/img/icon_logout.svg') no-repeat;*/
  width: 65px;height: 25px;
  border: 1px solid #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .topInfo > div {width: 136px;}
  .topInfo span {font-size: 18px;}
  .topBtn {flex-direction: row !important;font-size: 12px !important;gap: 5px !important;}
  .topBtn a {padding: 0 5px;font-size: 12px;height: 30px;}
  .topInfo-wrap {gap: 5px;}
  .topInfo {gap: 5px;}
  .topInfo > div {width: 100%;height: 30px;font-size: 12px;padding: 0 5px;flex-direction: row;justify-content: space-between;}
  .topInfo em i {display: none;}
  .topInfo span {font-size: 12px;}
}
@media screen and (max-width: 650px) {
  .topInfo-wrap {flex-direction: column;}
  .topBtn {font-size: 11px !important;gap: 3px !important;}
  .topBtn a {padding: 0 2px;height: 26px;font-size: 11px;}
  .topInfo {padding: 0 5px;gap: 3px;}
  .topInfo > div {font-size: 11px;}
}
</style>
