<template>
  <div class="tab-content" id="PTtab-12">
    <div class="pagenamPT">
      <h3 class="pagename2">하부파트너 포인트적립내역</h3>
    </div>
    <div class="sch_PC">
      <div class="PTsch flex-c">
        <div class="datesearchPTWarp flex-c gap-2">
          <h4>날짜</h4>
          <date-filter />
        </div>
      </div>
    </div>
    <div class="sch_M">
      <div class="PTsch flex-c">
        <div class="datesearchM">
          <date-filter-mobile />
        </div>
      </div>
    </div>
    <div class="sch_Normal">
      <div class="sch_Btm">
        <div class="searchPTwrap">
          <div class="searchPT">
            <div class="datesearchPT flex-c gap-2">
              <h4>처리여부</h4>
              <select v-model="searchForm.cashStatus" class="h36px">
                <option :value="''">{{$t('front.gameCategory.all')}}</option>
                <option :value="'1'">{{$t('front.give.ok')}}</option>
                <option :value="'-1'">{{$t('front.give.fail')}}</option>
              </select>
            </div>
            <div class="datesearchPT flex-c gap-2">
              <h4>처리내용</h4>
              <select v-model="searchForm.pointType" class="h36px">
                <option :value="''">{{$t('front.gameCategory.all')}}</option>
                <option :value="'2'">관리자지급</option>
                <option :value="'-2'">관리자회수</option>
                <option :value="'3'">롤링</option>
                <option :value="'-4'">포인트전환</option>
                <option :value="'5'">가입첫충이벤트</option>
                <option :value="'6'">매일첫충이벤트</option>
                <option :value="'7'">매충전이벤트</option>
              </select>
            </div>
          </div>
          <div class="datesearchPT flex-c gap-2 w30p">
            <h4>구분</h4>
            <select class="h36px">
              <option value="memId" selected>아이디</option>
              <option value="memNick">닉네임</option>
              <option value="recommenderId">상위유저</option>
            </select>
          </div>
          <div class="searchPT">
            <input type="text" v-model="searchForm.botMemId" class="h36px"/>
            <a @click="loadData(1)" class="sch_Icon">
              <img src="@/assets/img/search.png" alt=""/>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--
      <p>{{$t('front.give.date')}}:
        <input type="date" :value="searchForm.startDate" @change="onChangeStartDate"/>
        <span>~</span>
        <input type="date" :value="searchForm.endDate" @change="onChangeEndDate"/>
      </p>
    -->
    <div class="tab-content">
      <div class="strTableWrap">
        <div class="subMemWrap">
          <!-- <p class="p10"></p> -->
          <ul class="subPT_1">
            <h3>하부 파트너 목록</h3>
            <li v-for="(item1) in partnerList" :key="item1.memId" :class="{'morebar':item1.open}">
              <span class="arr" v-if="item1.cnt" :class="[item1.partnerLevel, {'moreOn':item1.open}]" @click="listOpen(item1.memId, 'sub', item1)"></span>
              <span class="arr normal" v-if="!item1.cnt"></span>
              <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                <span class="box" :class="item1.partnerLevel">본사</span>
                {{item1.memId}}
              </a>

              <ul class="subPT_2" v-show="item1.open && item1.children">
                <li v-for="item2 in item1.children" :key="item2.memId" :class="{'morebar':item2.open}">
                  <span class="arr" v-if="item2.cnt" :class="[item2.partnerLevel,{'moreOn':item2.open}]" @click="listOpen(item2.memId, 'sub', item2)"></span>
                  <span class="arr normal" v-if="!item2.cnt"></span>
                  <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                    <span class="box" :class="item2.partnerLevel">부본</span>
                    {{item2.memId}}
                  </a>

                  <ul class="subPT_3" v-show="item2.open && item2.children">
                    <li v-for="item3 in item2.children" :key="item3.memId" :class="{'morebar':item3.open}">
                      <span class="arr" v-if="item3.cnt" :class="[item3.partnerLevel,{'moreOn':item3.open}]" @click="listOpen(item3.memId, 'sub', item3)"></span>
                      <span class="arr normal" v-if="!item3.cnt"></span>
                      <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                        <span class="box" :class="item3.partnerLevel">{{partnerLevelName(item3.partnerLevel)}}</span>
                        {{item3.memId}}
                      </a>

                      <ul class="subPT_4" v-show="item3.open && item3.children">
                        <li v-for="item4 in item3.children" :key="item4.memId" :class="{'morebar':item4.open}">
                          <span class="arr" v-if="item4.cnt" :class="[item4.partnerLevel,{'moreOn':item4.open}]" @click="listOpen(item4.memId, 'sub', item4)"></span>
                          <span class="arr normal" v-if="!item4.cnt"></span>
                          <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                            <span class="box" :class="item4.partnerLevel">{{partnerLevelName(item4.partnerLevel)}}</span>
                            {{item4.memId}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="strTablePT">
              <thead>
                <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>처리내용</th>
                  <th>처리 전</th>
                  <th>처리 금액</th>
                  <th>처리 후</th>
                  <th>처리 일시</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="list.length > 0">
                  <template v-for="(item, index) in list" v-bind:key="item.pointIdx">
                    <tr>
                      <td>{{((pageInfo.page-1) * 10) +  (index + 1)}}</td>
                      <td>{{ item.memId }}</td>
                      <td class="nick">{{ item.memNick }}</td>
                      <td>
                        <span v-if="item.pointType === '2'">관리자지급 </span>
                        <span v-if="item.pointType === '-2'">관리자회수 </span>
                        <span v-if="item.pointType === '3'">롤링 </span>
                        <span v-if="item.pointType === '-4'">포인트전환 </span>
                        <span v-if="item.pointType === '5'">가입첫충이벤트 </span>
                        <span v-if="item.pointType === '6'">매일첫충이벤트 </span>
                        <span v-if="item.pointType === '7'">매충전이벤트</span>
                      </td>
                      <td>{{ thousand(item.prePointAmt) }}</td>
                      <td>{{ thousand(item.pointAmt) }}</td>
                      <td>{{ thousand(item.pointAmt + item.prePointAmt) }}</td>
                      <td>{{ item.pointRegDate.replace('T', ' ') }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="list.length > 0">
                    <template v-for="(item, index) in list" v-bind:key="item.pointIdx">
                      <ul>
                        <li>
                          <em>번호</em>
                          <div>{{((pageInfo.page-1) * 10) +  (index + 1)}}</div>
                        </li>
                        <li>
                          <em>처리 전</em>
                          <div>{{ thousand(item.prePointAmt) }}</div>
                        </li>
                        <li>
                          <em>아이디</em>
                          <div>{{ item.memId }}</div>
                        </li>
                        <li>
                          <em>처리 금액</em>
                          <div>{{ thousand(item.pointAmt) }}</div>
                        </li>
                        <li>
                          <em>닉네임</em>
                          <div class="nick">{{ item.memNick }}</div>
                        </li>
                        <li>
                          <em>처리 후</em>
                          <div>{{ thousand(item.pointAmt + item.prePointAmt) }}</div>
                        </li>
                        <li>
                          <em>처리 내용</em>
                          <div>
                            <template v-if="item.pointType === '2'">관리자지급 </template>
                            <template v-if="item.pointType === '-2'">관리자회수 </template>
                            <template v-if="item.pointType === '3'">롤링 </template>
                            <template v-if="item.pointType === '-4'">포인트전환 </template>
                            <template v-if="item.pointType === '5'">가입첫충이벤트 </template>
                            <template v-if="item.pointType === '6'">매일첫충이벤트 </template>
                            <template v-if="item.pointType === '7'">매충전이벤트</template>
                          </div>
                        </li>
                        <li>
                          <em>포인트 처리 일시</em>
                          <div>
                            {{ item.pointRegDate.replace('T', ' ') }}
                          </div>
                        </li>
                      </ul>
                    </template>
                  </template>
                  <template v-else>
                  <ul>
                      <li class="nodata">내역 없음</li>
                  </ul>
                </template>
              </div>
          </div>
        </div>
      </div>
      <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        :className="'partnerPaging'"
        @goToPage="loadData"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination.vue'
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import {
  getRetailMyLoginMem,
  partnerLevels,
  partnerMyMemIds,
  retailMyPartnerDirectList,
  subPartnerPointList
} from '@/api/retail'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
import { mapState } from 'vuex'

export default {
  name: 'subpartnerPointList',
  components: { Pagination, DateFilter, DateFilterMobile },
  data () {
    return {
      model: {},
      selectMemId: '',
      selectMem: {
        cashAmt: ''
      },
      cashAmt: '',
      list: [],
      searchForm: {
        topMemId: '',
        pointType: '',
        cashStatus: '',
        botMemId: '',
        startDate: getDateStr(getSubDaysDate(new Date(), 7)),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd')
      },
      partnerLevelList: [],
      partnerList: [],
      selectPartnerCode: '',
      myMemIdsList: [],
      isProcessing: false,
      loginMemList: [],
      selectedLi: null,
      idlist: false
    }
  },
  watch: {
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      }
    },
    selectMem () {
      if (this.selectMem) {
        this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ]),
    formattedCashAmt () {
      if (!this.cashAmt) return ''
      return this.cashAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  async created () {
    this.getPartnerLevels()
    await this.getLoginMember()
    this.getMyPartnerList(this.userData.memId)
    this.loadData()
  },
  methods: {
    thousand,
    partnerLevelName (partnerLevel) {
      return PARTNER_LEVEL_NAME[partnerLevel]
    },
    async getLoginMember () {
      const res = await getRetailMyLoginMem()
      console.log(res)
      const result = res.data
      if (result.resultCode === '0') {
        this.loginMemList = result.data.loginMemList
      }
    },
    parseFormattedValue (value) {
      return value.replace(/,/g, '')
    },
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    listOpen (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId
      retailMyPartnerDirectList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = !item.open
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getMyPartnerList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId

      console.log('[REQ]retailMyPartnerDirectList : ', params)
      retailMyPartnerDirectList(params).then(res => {
        console.log('[RES]retailMyPartnerDirectList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = true

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.loadData(1)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
        }
      })
    },
    onChangeStartDate (event) {
      const startDate = event.target.value
      this.searchForm.startDate = startDate
    },
    onChangeEndDate (event) {
      const endDate = event.target.value
      this.searchForm.endDate = endDate
    },
    loadData (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      if (this.selectedLi) {
        this.searchForm.topMemId = this.selectedLi
      }
      const form = JSON.parse(JSON.stringify(this.searchForm))
      form.startDate += ' 00:00:00'
      form.endDate += ' 23:59:59'
      form.pointTypeList = []
      // form.page = page

      if (form.pointType) {
        form.pointTypeList.push(form.pointType)
      }

      const params = {
        ...form,
        page: page,
        count_per_list: 40
      }
      this.emitter.emit('Loading', true)
      console.log('subPartnerPointList : ', params)
      subPartnerPointList(params).then(response => {
        this.emitter.emit('Loading', false)
        console.log('subPartnerPointList : ', response)
        const result = response.data
        if (result.resultCode === '0') {
          this.list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    }
  }
}
</script>
<style scoped src="@/styles/striNew.css"></style>
<style scoped>
.datesearchPT input[type="date"]::-webkit-calendar-picker-indicator{/*background: url(../../../assets/img/PTcalender.svg);display: block;background-repeat: no-repeat;background-size: contain;*/}
.strTablescr {width: 100%;}

@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
  .strTablePTM li:nth-child(5), .strTablePTM li:nth-child(6), .strTablePTM li:nth-child(7) {}
}
</style>
