<template>
  <div class="tab-content" id="PTtab-5" :key="'PTtab-5'" v-if="isLoaded">
      <div class="pagenamPT">
        <h3 class="pagename2">메인</h3>
      </div>
      <div class="mainInfo">
        <div class="a">
          <em>충전 ({{thousand(mainStatisticsInfo.dCashInCnt || 0)}}건)</em>
          <span>{{thousand(mainStatisticsInfo.dCashIn || 0)}}</span>
        </div>
        <div class="a">
          <em>환전 ({{thousand(mainStatisticsInfo.dCashOutCnt || '0')}}건)</em>
          <span>{{thousand(mainStatisticsInfo.dCashOut || '0')}}</span>
        </div>
        <div class="b">
          <em>알 지급</em>
          <span>{{thousand(mainStatisticsInfo.dPayment || 0)}}</span>
        </div>
        <div class="b">
          <em>알 회수</em>
          <span>{{thousand(mainStatisticsInfo.dPayback || 0)}}</span>
        </div>
        <div class="c">
          <em>금일 신규 회원</em>
          <span>{{thousand(mainStatisticsInfo.dJoinCnt || 0)}}</span>
        </div>
        <div class="c">
          <em>금일 베팅손익 <i>(베팅 -당첨 - 총롤링금)</i></em>
          <span>{{thousand(mainStatisticsInfo.dBetBEP || 0)}}</span>
        </div>
      </div>

      <div class="tableResult">
        <div class="title">
          <h4>[머니 입출금 개요]<br />보유 잔액 = 보유머니 + 롤링금, 충환전은 소속 회원에게 충환전된 금액 합계</h4>
          <date-filter-Pt :startDate="cashReportTable.startDate"
                          :endDate="cashReportTable.endDate"
                          @update="updateDateCashReport"
                          @search="loadCashReport" />
        </div>
        <div class="strTableWrap">
          <div class="strTablescr">
            <div class="strTablePC">
              <table>
                <colgroup>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                  <col width="8.3%"/>
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="3" class="bgb">충전</th>
                    <th colspan="3" class="bgc">환전</th>
                    <th colspan="2" class="bgd">현재 보유금</th>
                    <th colspan="3" class="bgb">베팅 / 당첨</th>
                    <th colspan="2" rowspan="2" class="bga">롤링금</th>
                  </tr>
                  <tr>
                    <th class="bgb">충전</th>
                    <th class="bgb">알 지급</th>
                    <th class="bgb">충전 합계</th>
                    <th class="bgc">환전</th>
                    <th class="bgc">알 회수</th>
                    <th class="bgc">환전 합계</th>
                    <th class="bgd">회원보유잔액</th>
                    <th class="bgd">파트너보유잔액</th>
                    <th class="bgb">베팅금</th>
                    <th class="bgb">당첨금</th>
                    <th class="bgb">베팅 - 당첨</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{thousand(cashReport.cashIn || 0)}}</td>
                    <td>{{thousand(cashReport.payment || 0)}}</td>
                    <td>{{thousand(cashReport.inPaymentSum || 0)}}</td>
                    <td>{{thousand(cashReport.cashOut || 0)}}</td>
                    <td>{{thousand(cashReport.payback || 0)}}</td>
                    <td>{{thousand(cashReport.outPaybackSum || 0)}}</td>
                    <td>{{thousand(cashReport.normalCashAmt || 0)}}</td>
                    <td>{{thousand(cashReport.partnerCashAmt || 0)}}</td>
                    <td>{{thousand(cashReport.betAmt || 0)}}</td>
                    <td>{{thousand(cashReport.winAmt || 0)}}</td>
                    <td>{{thousand(cashReport.betBEP || 0)}}</td>
                    <td>{{thousand(cashReport.totalPointAmt || 0)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="strTableM">
              <div class="strTablePTM">
                <table>
                  <colgroup>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th colspan="3" class="bgb">충전</th>
                      <th colspan="3" class="bgc">환전</th>
                    </tr>
                    <tr>
                      <th class="bgb">충전</th>
                      <th class="bgb">알 지급</th>
                      <th class="bgb">충전 합계</th>
                      <th class="bgc">환전</th>
                      <th class="bgc">알 회수</th>
                      <th class="bgc">환전 합계</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{thousand(cashReport.cashIn || 0)}}</td>
                      <td>{{thousand(cashReport.payment || 0)}}</td>
                      <td>{{thousand(cashReport.inPaymentSum || 0)}}</td>
                      <td>{{thousand(cashReport.cashOut || 0)}}</td>
                      <td>{{thousand(cashReport.payback || 0)}}</td>
                      <td>{{thousand(cashReport.outPaybackSum || 0)}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="mt20">
                  <colgroup>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th colspan="2" class="bgd">현재 보유금</th>
                      <th colspan="3" class="bgb">베팅 / 당첨</th>
                      <th rowspan="2" class="bga">롤링금</th>
                    </tr>
                    <tr>
                      <th class="bgd">회원보유잔액</th>
                      <th class="bgd">파트너보유잔액</th>
                      <th class="bgb">베팅금</th>
                      <th class="bgb">당첨금</th>
                      <th class="bgb">베팅 - 당첨</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{thousand(cashReport.normalCashAmt || 0)}}</td>
                      <td>{{thousand(cashReport.partnerCashAmt || 0)}}</td>
                      <td>{{thousand(cashReport.betAmt || 0)}}</td>
                      <td>{{thousand(cashReport.winAmt || 0)}}</td>
                      <td>{{thousand(cashReport.betBEP || 0)}}</td>
                      <td>{{thousand(cashReport.totalPointAmt || 0)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tableResult">
        <div class="title">
          <h4>나의 자금 변동내역</h4>
          <date-filter-Pt :startDate="myCashReportTable.startDate"
                          :endDate="myCashReportTable.endDate"
                          @update="updateDateMyCashReport"
                          @search="loadMyCashReport" />
        </div>
        <div class="strTableWrap">
          <div class="strTablescr">
            <div class="strTablePC">
              <table>
                <colgroup>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                  <col width="9%"/>
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="6" class="bgb">보유금 증가</th>
                    <th colspan="5" class="bgc">보유금 감소</th>
                  </tr>
                  <tr>
                    <th class="bgb">입금</th>
                    <th class="bgb">관리자지급</th>
                    <th class="bgb">상부지급</th>
                    <th class="bgb">하부회수</th>
                    <th class="bgb">롤링전환</th>
                    <th class="bgb">증가합계</th>
                    <th class="bgc">출금</th>
                    <th class="bgc">관리자회수</th>
                    <th class="bgc">상부회수</th>
                    <th class="bgc">하부지급</th>
                    <th class="bgc">감소합계</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{thousand(myCashReport.cashIn || 0)}}</td>
                    <td>{{thousand(myCashReport.adminPayment || 0)}}</td>
                    <td>{{thousand(myCashReport.upperPayment || 0)}}</td>
                    <td>{{thousand(myCashReport.myPayback || 0)}}</td>
                    <td>{{thousand(myCashReport.changePoint || 0)}}</td>
                    <td>{{thousand(myCashReport.increaseTotal || 0)}}</td>
                    <td>{{thousand(myCashReport.cashOut || 0)}}</td>
                    <td>{{thousand(myCashReport.adminPayback || 0)}}</td>
                    <td>{{thousand(myCashReport.upperPayback || 0)}}</td>
                    <td>{{thousand(myCashReport.myPayment || 0)}}</td>
                    <td>{{thousand(myCashReport.decreaseTotal || 0)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="strTableM">
              <div class="strTablePTM">
                <table>
                  <colgroup>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                    <col width="16.6%"/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th colspan="6" class="bgb">보유금 증가</th>
                    </tr>
                    <tr>
                      <th class="bgb">입금</th>
                      <th class="bgb">관리자지급</th>
                      <th class="bgb">상부지급</th>
                      <th class="bgb">하부회수</th>
                      <th class="bgb">롤링전환</th>
                      <th class="bgb">증가합계</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{thousand(myCashReport.cashIn || 0)}}</td>
                      <td>{{thousand(myCashReport.adminPayment || 0)}}</td>
                      <td>{{thousand(myCashReport.upperPayment || 0)}}</td>
                      <td>{{thousand(myCashReport.myPayback || 0)}}</td>
                      <td>{{thousand(myCashReport.changePoint || 0)}}</td>
                      <td>{{thousand(myCashReport.increaseTotal || 0)}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="mt20">
                  <colgroup>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="20%"/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th colspan="5" class="bgc">보유금 감소</th>
                    </tr>
                    <tr>
                      <th class="bgc">출금</th>
                      <th class="bgc">관리자회수</th>
                      <th class="bgc">상부회수</th>
                      <th class="bgc">하부지급</th>
                      <th class="bgc">감소합계</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{thousand(myCashReport.cashOut || 0)}}</td>
                      <td>{{thousand(myCashReport.adminPayback || 0)}}</td>
                      <td>{{thousand(myCashReport.upperPayback || 0)}}</td>
                      <td>{{thousand(myCashReport.myPayment || 0)}}</td>
                      <td>{{thousand(myCashReport.decreaseTotal || 0)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="notiWrap notice">
        <div>
          <h4>공지사항</h4>
          <ul class="head">
            <li style="width: 10%;">#</li>
            <li style="width: 65%;">제목</li>
            <li style="width: 25%;">작성일시</li>
          </ul>
          <template>{{boardList}}</template>
          <template v-if="boardList && boardList.length > 0">
            <template v-for="item in boardList" :key="item.boardIdx">
              <ul class="body">
                <li style="width: 10%;">{{item.boardIdx}}</li>
                <li style="width: 65%;"><a @click.prevent="onClickRead(item.boardIdx)">{{item.title}}</a></li>
                <li style="width: 25%;">{{item.regDate}}</li>
              </ul>
            </template>
          </template>
          <template v-else>
            <ul class="body">
              <li style="width: 100%;">공지사항이 없습니다.</li>
            </ul>
          </template>
        </div>
      </div>

      <div class="notiWrap">
        <div>
          <h4>최근 지급</h4>
          <ul class="head">
            <li style="width: 5%;">#</li>
            <li style="width: 35%;">닉네임</li>
            <li style="width: 25%;">금액</li>
            <li style="width: 35%;">작성일시</li>
          </ul>
          <template v-if="newPaymentList && newPaymentList.length">
            <template v-for="(item, idx) in newPaymentList" :key="item.updDate">
              <ul class="body">
                <li style="width: 5%;">{{idx + 1}}</li>
                <li style="width: 35%;">{{item.memNick}}</li>
                <li style="width: 25%;">{{ thousand(item.cashAmt || 0) }}</li>
                <li style="width: 35%;">{{item.updDate.replace('T', ' ')}}</li>
              </ul>
            </template>
          </template>
          <template v-else>
            <ul class="body">
              <li style="width: 100%;">최근 지급 내역이 없습니다.</li>
            </ul>
          </template>
        </div>
        <div>
          <h4>최근 회수</h4>
          <ul class="head">
            <li style="width: 5%;">#</li>
            <li style="width: 35%;">닉네임</li>
            <li style="width: 25%;">금액</li>
            <li style="width: 35%;">작성일시</li>
          </ul>
          <template v-if="newPaybackList && newPaybackList.length">
            <template v-for="(item, idx) in newPaybackList" :key="item.updDate">
              <ul class="body">
                <li style="width: 5%;">{{idx + 1}}</li>
                <li style="width: 35%;">{{item.memNick}}</li>
                <li style="width: 25%;">{{ thousand(item.cashAmt || 0) }}</li>
                <li style="width: 35%;">{{item.updDate.replace('T', ' ')}}</li>
              </ul>
            </template>
          </template>
          <template v-else>
            <ul class="body">
              <li style="width: 100%;">최근 회수 내역이 없습니다.</li>
            </ul>
          </template>
        </div>
      </div>

  </div>
</template>

<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import { getMainCashReport, getMainMyCashReport, getMainNewPay, getMainStatistics } from '@/api/v2/main'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { getMainNotice } from '@/api/main'
export default {
  name: 'partnerMain',
  components: { DateFilterPt },
  computed: {
    isLoaded () {
      return this.mainStatisticsInfo && this.cashReport && this.myCashReport
    }
  },
  data () {
    return {
      cashReportTable: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      myCashReportTable: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      mainStatisticsInfo: null,
      cashReport: null,
      myCashReport: null,
      boardList: [],
      newPaymentList: [],
      newPaybackList: []
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.loadStatistics()
    this.loadCashReport()
    this.loadMyCashReport()
    this.loadMainNotice()
    this.loadNewPayLog()
    this.emitter.emit('Loading', false)
  },
  watch: {
  },
  methods: {
    thousand,
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'noticeRead', params: { boardIdx: id } })
    },
    loadStatistics () {
      getMainStatistics({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.mainStatisticsInfo = { ...result.data.mainStatisticsInfo }
          console.log('mainStatisticsInfo :', this.mainStatisticsInfo)
        }
      })
    },
    loadCashReport () {
      const param = {
        startDate: this.cashReportTable.startDate.split(' ')[0],
        endDate: this.cashReportTable.endDate.split(' ')[0]
      }
      console.log('cashREport : ', param)
      getMainCashReport(param).then(res => {
        const result = res.data
        console.log('cashReport :', result)
        if (result.resultCode === '0') {
          this.cashReport = { ...result.data.cashReport }
        } else {
          this.onAlert('warning', result.resultMessage)
        }
      })
    },
    loadMyCashReport () {
      const param = {
        startDate: this.myCashReportTable.startDate.split(' ')[0],
        endDate: this.myCashReportTable.endDate.split(' ')[0]
      }
      console.log('mycashREport : ', param)
      getMainMyCashReport(param).then(res => {
        const result = res.data
        console.log('mycashREport :', result)
        if (result.resultCode === '0') {
          this.myCashReport = { ...result.data.myCashReport }
        } else {
          this.onAlert('warning', result.resultMessage)
        }
      })
    },
    updateDateCashReport (value) {
      this.cashReportTable = { ...value }
    },
    updateDateMyCashReport (value) {
      this.myCashReportTable = { ...value }
    },
    loadMainNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        console.log('getMainNotice :', result)
        if (result.resultCode === '0') {
          this.boardList = result.data.boardList
        } else {
          this.onAlert('warning', result.resultMessage)
        }
      })
    },
    loadNewPayLog () {
      getMainNewPay({}).then(res => {
        const result = res.data
        console.log('getMainNewPay :', result)
        if (result.resultCode === '0') {
          this.newPaymentList = result.data.mainNewPayLog.newPaymentList
          this.newPaybackList = result.data.mainNewPayLog.newPaybackList
        } else {
          this.onAlert('warning', result.resultMessage)
        }
      })
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style scoped>
.mainInfo {display: flex;align-items: center;gap: 10px;}
.mainInfo > div {width: 100%;height: 70px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background-color: #fff;display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 15px;box-sizing: border-box;}
.mainInfo em {color: #535c6d;font-size: 12px;font-weight: 800;text-align: center;line-height: 1.2em;}
.mainInfo span {color: #061738;font-size: 20px;font-weight: 900;}
.mainInfo > div.a {border-bottom: 2px solid #ffa200;}
.mainInfo > div.b {border-bottom: 2px solid #63cc27;}
.mainInfo > div.c {border-bottom: 2px solid #72a2ff;}

.notiWrap {display: flex;gap: 10px;margin-top: 40px;}
.notiWrap > div {width: 100%;}
.notiWrap h4 {padding: 0 15px;font-size: 15px;color: #000;height: 37px;display: flex;align-items: center;justify-content: space-between;font-weight: 800;}
.btna {display: flex;align-items: center;height: 30px;padding: 0 18px;border-radius: 3px;box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);background-color: #b71fdb;font-size: 15px;color: #fff;cursor: pointer;}
.btnb {display: flex;align-items: center;height: 20px;padding: 0 12px;border-radius: 3px;box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);background-color: #2586db;font-size: 12px;color: #fff;cursor: pointer;}
.btnc {display: flex;align-items: center;height: 20px;padding: 0 12px;border-radius: 3px;box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);background-color: #25db91;font-size: 12px;color: #fff;cursor: pointer;}
.btnd {display: flex;align-items: center;height: 20px;padding: 0 12px;border-radius: 3px;box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);background-color: #ba0f0f;font-size: 12px;color: #fff;cursor: pointer;}
.btne {display: flex;align-items: center;height: 20px;padding: 0 12px;border-radius: 3px;box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);background-color: #5c4ac7;font-size: 12px;color: #fff;cursor: pointer;}
.btnf {display: flex;align-items: center;height: 20px;padding: 0 12px;border-radius: 3px;box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);background-color: #ef5350;font-size: 12px;color: #fff;cursor: pointer;}
.notiWrap ul {height: 35px;display: flex;flex-wrap: wrap;font-size: 12px;color: #000;border-top: 1px solid #bdbfc4;box-sizing: border-box;background-color: #fff;}
.notiWrap ul.head {background-color: #ddf6ff;font-weight: 900;}
.notiWrap ul li {display: flex;align-items: center;justify-content: center;}
.notiWrap.notice ul.head {background-color: #e9e1f6;}

.tableResult {margin-top: 30px;padding: 11px 7px 13px;border-radius: 3px;box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);background-color: #fff;}
.tableResult .title {display: flex;align-items: center;justify-content: space-between;font-size: 15px;color: #000;line-height: 1.5em;}
.tableResult .title h4 {font-weight: 800;}
.tableResult table {width: 100%;text-align: center;vertical-align: middle;font-size: 13px; overflow: hidden; border-collapse: collapse;border-spacing: 0;}
.tableResult table th {height: 35px;color: #000;vertical-align: middle; border: 2px solid #bdbfc4;line-height: 1.5em;font-weight: 900;}
.tableResult table td {box-sizing: border-box; padding: 9px 0;vertical-align: middle;border: 2px solid #bdbfc4;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;position: relative; font-size: 13px;line-height: 1.2em;}
th.bga {background-color: #d8e0e9;}
th.bgb {background-color: #ddf6ff;}
th.bgc {background-color: #fce7e4;}
th.bgd {background-color: #e9e1f6;}

.strTableWrap {min-height: auto;}

.flex-c {
  flex-direction: column;
}
.fx-center-gap {display: flex;gap: 10px;}
.gap-2 {
  gap: 2px;
}
.subPT_1 li {
  position: relative;
}
.allBtn {
  background: #e50000;
  color: #fff;
  width: 57px;
  height: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #000;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
  .tableResult .title,
  .notiWrap {flex-direction: column;gap: 15px;}

  .mainInfo {flex-wrap: wrap;}
  .mainInfo > div {width: calc(33.33% - 7px);gap: 5px;}
  .mainInfo em {font-size: 11px;height: 27px;display: flex;align-items: center;flex-direction: column;justify-content: center;}
}

</style>
